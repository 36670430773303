import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from 'lodash';

import * as AccountAction from "../../redux/actions/accountAction";

import styles from "./share-button.module.css";

function Index(props) {
  const handleClickShareButton = async () => {
    let showDefault = true;
    if(props.showDefault !== undefined){
      showDefault = props.showDefault;
    }
    if (!_.isEmpty(props.mgmCode)) {
      props.dispatch(
        AccountAction.updateValue({
          name: "showNamecard",
          value: true
        })
      );
      props.dispatch(
        AccountAction.updateValue(
          {
            name: "showDefault",
            value: showDefault
          })
      );
    } else {
      props.history.push(`/${props.match.params.lang}#account`);
    }
  };

  return (
    <div className={`button ${styles["button"]}`} onClick={() => handleClickShareButton()} onClick={() => handleClickShareButton()} style={{ backgroundColor: `${props && props.backgroundColor}` }}>
      <img src={props.icon} />
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        account: state.account
      }
    };
  })(Index)
);
