export default (state = {
    jsonMapping: {}
}, action) => {
    switch (action.type) {
        case 'UPDATE_CMS':
            return {
              ...state,
              [action.payload.name]: action.payload.value
            }
        default:
            return state;
    }
}
