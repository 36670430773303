import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import htmlParser from "react-html-parser";
import _ from "lodash";

import styles from "./tnc.module.css";

function TNC(props) {
  const [staticContent, setStaticContent] = useState(null);
  const [tncContent, setTncContent] = useState(null);


  const getCMSJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryTncContents`,
      method: "get"
    });
    // setStaticContent(cmsJson.data.map(d => d.data)[0]);
    setStaticContent(cmsJson.data.map(d => d.data));
  };

  useEffect(() => {
    getCMSJson();
    window.scroll(0, 0)
  }, []);

  useEffect(() => {
    if (window.location.hash && staticContent) {
      var hash = _.replace(window.location.hash, "#", "");
      var tnc = _.find(staticContent, { "serviceType": { "iv": [{ "data": { "abbreviation": { "iv": hash } } }] } })
      if (tnc) {
        // Find tnc by service type
        setTncContent(tnc);
      }
      else {
        // Find default tnc with no service type
        console.log("hash not found")
        tnc = _.find(staticContent, function (o) { return o.serviceType.iv.length <= 0; })
        setTncContent(tnc);
      }
      if (tnc === undefined && staticContent && _.size(staticContent) > 0) {
        // If tnc still cannot be found, use the first one found
        console.log("tnc undefined")
        tnc = staticContent[0];
        setTncContent(tnc);
      }
    }
    else if (_.isEmpty(window.location.hash) && staticContent) {
      // Find default tnc with no service type
      console.log("No service type: hash not found")
      tnc = _.find(staticContent, function (o) { return o.serviceType.iv.length <= 0; })
      setTncContent(tnc);
      if (tnc === undefined && staticContent && _.size(staticContent) > 0) {
        // If tnc still cannot be found, use the first one found
        console.log("tnc undefined")
        tnc = staticContent[0];
        setTncContent(tnc);
      }
    }
  }, [window.location.hash, staticContent]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["inner-wrapper"]}>
        {_.isEqual(props.state.languageCode, "zh_HK") && tncContent && htmlParser(tncContent.tnc.iv)}
        <br></br>
        {_.isEqual(props.state.languageCode, "en") && tncContent && htmlParser(tncContent.tncen.iv)}
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(TNC)
);
