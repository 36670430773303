import React, { Component, Suspense, useState, useEffect } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withRouter } from "react-router";
import rootReducer from "../redux/reducers/rootRedducer";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18next";
import axios from 'axios'

import RouteLanguage from "./Route-Language";
import RouteMain from "./Route-Main";
import Tealium from "../components/Tealium";

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

function App(props) {
  const [colorTheme, setColorTheme] = useState([]);

  const getCMSColorTheme = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryColorThemeContents`,
      method: "get"
    });
    setColorTheme(cmsJson.data.map(d => d.data));
  };

  const genCSSVariable = () => {
    let cssString = ``
    for(let c of colorTheme){
      cssString += `--${c.name.iv}: ${c.colorCode.iv};`
    }
    return cssString
  }

  useEffect(() => {
    getCMSColorTheme();
  }, []);

  return (
    <Provider store={store}>
      <style>
        {`
          :root{
            ${genCSSVariable()}
          }
        `}
      </style>
      <I18nextProvider i18n={i18n}>
        <Router basename={process.env.REACT_APP_Router_BaseName}>
          <RouteLanguage />
          <Tealium></Tealium>
        </Router>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
