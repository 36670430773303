import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./get-referral-code.module.css";

import EmailShareButton from "../../share-button/email";
import WhatsappShareButton from "../../share-button/whatsapp";
import FacebookShareButton from "../../share-button/facebook";
import NameCardShareButton from "../../share-button/namecard";
import Namecard from "../Namecard/index";

function Account(props) {
  const [getReferralCodeLoading, setGetReferralCodeLoading] = useState(false);
  const [getReferralCodeMobile, setGetReferralCodeMobile] = useState("");
  const [getReferralCodeCustomerID, setGetReferralCodeCustomerID] = useState("");
  const [getReferralCodeState, setGetReferralCodeState] = useState("mobile");
  const [getCodeErrorMessage, setGetCodeErrorMessage] = useState(null);

  const [referralCode, setReferralCode] = useState(null);

  const getReferralCode = () => {
    switch (getReferralCodeState) {
      case "mobile":
        if (getReferralCodeMobile) {
          getReferralCodeRequest(getReferralCodeMobile);
        }
        break;
      case "customerID":
        if (getReferralCodeCustomerID) {
          getReferralCodeRequest(getReferralCodeMobile, getReferralCodeCustomerID);
        }
        break;
    }
  };

  const getReferralCodeRequest = async (mobile, customerID = null) => {
    if (!getReferralCodeLoading) {
      setGetReferralCodeLoading(true);
      try {
        let referralCodeResponse = await axios({
          url: `${process.env.REACT_APP_Api_Url}/referral/get-referral-code?mobile=${mobile}${customerID != null ? `&customerID=${customerID}` : ""}`,
          method: "get"
        });
        switch (referralCodeResponse.data.errorMessage) {
          case "provide-customerID":
            setGetReferralCodeState("customerID");
            break;
          case "invalid-mobile":
            setGetCodeErrorMessage(props.staticContent.getcodeInvalidMobile[props.state.languageCode]);
            break;
          case "invalid-customerID":
            setGetCodeErrorMessage(props.staticContent.getcodeInvalidPPS[props.state.languageCode]);
            break;
          case null:
            setReferralCode(referralCodeResponse.data.referralCode);
            setGetReferralCodeState("referral-code");
            break;
        }
        setGetReferralCodeLoading(false);
      } catch (err) {
        setGetReferralCodeLoading(false);
      }
    }
  };

  const getReferralCodeSection = () => {
    switch (getReferralCodeState) {
      case "mobile":
        return (
          <div className={styles["text-box-wrapper"]}>
            <input
              type="text"
              autoComplete="off"
              className={`textbox ${styles["txt-phone"]}`}
              placeholder={props.staticContent && props.staticContent.txtPhonePlaceHolder[props.state.languageCode]}
              value={getReferralCodeMobile}
              onChange={e => setGetReferralCodeMobile(e.target.value)}
              disabled={getReferralCodeLoading}
            />
            <div className={`button ${styles["btn-getcode-wrapper"]}`} onClick={() => getReferralCode()} style={{color: `var(--${props.staticContent && props.staticContent.btnGetReferralCodeIconColor.iv[0].data.name.iv})`, backgroundColor: `var(--${props.staticContent && props.staticContent.btnGetReferralCodeBackgroundColor.iv[0].data.name.iv})`}}>
              <FontAwesomeIcon icon={faChevronRight} className={`button ${styles["btn-getcode"]}`} />
            </div>
          </div>
        );
      case "customerID":
        return (
          <div className={styles["text-box-wrapper"]}>
            <input
              type="text"
              autoComplete="off"
              className={`textbox ${styles["txt-phone"]}`}
              placeholder={props.staticContent && props.staticContent.txtGetCodePPSPlaceHolder[props.state.languageCode]}
              value={getReferralCodeCustomerID}
              onChange={e => setGetReferralCodeCustomerID(e.target.value)}
              disabled={getReferralCodeLoading}
            />
            <div className={`button ${styles["btn-getcode-wrapper"]}`} onClick={() => getReferralCode()}  style={{color: `var(--${props.staticContent && props.staticContent.btnGetReferralCodeIconColor.iv[0].data.name.iv})`, backgroundColor: `var(--${props.staticContent && props.staticContent.btnGetReferralCodeBackgroundColor.iv[0].data.name.iv})`}}>
              <FontAwesomeIcon icon={faChevronRight} className={`button ${styles["btn-getcode"]}`} />
            </div>
          </div>
        );
      case "referral-code":
        return (
          <div className={styles["text-box-wrapper"]}>
            <input type="text" className={`textbox ${styles["txt-referral-code"]}`} readOnly value={referralCode} autoComplete="off" />
            <CopyToClipboard text={`${process.env.REACT_APP_ReferralShortURL}${referralCode}`}>
              <div className={`button ${styles["btn-copy-wrapper"]}`}  style={{color: `var(--${props.staticContent && props.staticContent.btnCopyTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${props.staticContent && props.staticContent.btnCopyBackgroundColor.iv[0].data.name.iv})`}}>
                <div className={styles["icon-copy-wrapper"]}>
                  <img src={props.staticContent && props.staticContent.btnCopyIcon.iv[0].url} />
                </div>
                <span>{props.staticContent && props.staticContent.btnCopyText[props.state.languageCode]}</span>
              </div>
            </CopyToClipboard>
          </div>
        );
    }
  };

  return (
    <div className={styles["get-referral-code-wrapper"]}>
      <div className={styles["get-referral-code-inner-wrapper"]}>
        <h4>{props.staticContent && props.staticContent.getReferralCodeTitle[props.state.languageCode]}</h4>
        {getReferralCodeSection()}
        <div className={styles["error-message"]}>{getCodeErrorMessage && getCodeErrorMessage}</div>
        {getReferralCodeState == "referral-code" && (
          <div className={styles["share-buttons-wrapper"]}>
            <FacebookShareButton icon={props.staticContent && props.staticContent.btnShareFacebookIcon.iv[0].url} mgmCode={referralCode} backgroundColor={props.staticContent && props.staticContent.btnShareFacebookButtonBackgroundColor.iv[0].data.colorCode.iv} showDefault={true} />
            <WhatsappShareButton icon={props.staticContent && props.staticContent.btnShareWhatsappIcon.iv[0].url} mgmCode={referralCode} backgroundColor={props.staticContent && props.staticContent.btnShareWhatsappButtonBackgroundColor.iv[0].data.colorCode.iv} showDefault={true} />
            <EmailShareButton icon={props.staticContent && props.staticContent.btnShareEmailIcon.iv[0].url} mgmCode={referralCode} backgroundColor={props.staticContent && props.staticContent.btnShareEmailButtonBackgroundColor.iv[0].data.colorCode.iv} showDefault={true} />
            <NameCardShareButton icon={props.staticContent && props.staticContent.btnShareNamecardIcon.iv[0].url} mgmCode={referralCode} backgroundColor={props.staticContent && props.staticContent.btnShareNamecardButtonBackgroundColor.iv[0].data.colorCode.iv} showDefault={true} />
            {props.state.account.showNamecard && <Namecard referralCode={referralCode} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account
      }
    };
  })(Account)
);
