import _ from 'lodash';

const replaceShareMessage = (shareMessage, platform, languageCode, mgmCode, serviceType) => {
    var output = shareMessage;
    var shortenUrl = getShortenLink(platform, languageCode, mgmCode, null);
    var esUrl = getEsShorternUrl(languageCode, mgmCode);
    var dplusUrl = getShortenLink("disney", languageCode, mgmCode);
    output = formatString(shareMessage, [mgmCode, shortenUrl, esUrl, dplusUrl]);
    return output;
}

const getShortenLink = (platform, languageCode, mgmCode, serviceType) => {
    var p = '';
    switch (platform) {
        case "facebook":
            p = "f";
            break;
        case "whatsapp":
            p = "w";
            break;
        case "email":
            p = "m";
            break;
        case "edm-facebook":
            p = "ef";
            break;
        case "edm-whatsapp":
            p = "ew";
            break;
        case "edm-email":
            p = "em";
            break;
        case "edm-line":
            p = "el";
            break;
        case "disney":
            p = "d";
            break;
        case "":
            break;
    }

    switch (languageCode) {
        case "tc":
        case "zh_HK":
            if (_.isEmpty(platform)) {
                return formatString("{0}/{1}", [process.env.REACT_APP_ReferralShortURL, mgmCode]);
            }
            else {
                if (!_.isEmpty(serviceType)) {
                    return formatString("{0}/{1}/{2}/{3}", [process.env.REACT_APP_ReferralShortURL, p, mgmCode, serviceType]);
                }
                else {
                    return formatString("{0}/{1}/{2}", [process.env.REACT_APP_ReferralShortURL, p, mgmCode]);
                }
            }
        case "en":
        default:
            if (_.isEmpty(platform)) {
                return formatString("{0}/{1}/{2}", [process.env.REACT_APP_ReferralShortURL, mgmCode, languageCode]);
            }
            else {
                if (!_.isEmpty(serviceType)) {
                    return formatString("{0}/{1}/{2}/{3}/{4}", [process.env.REACT_APP_ReferralShortURL, p, mgmCode, languageCode, serviceType]);
                }
                else {
                    return formatString("{0}/{1}/{2}/{3}", [process.env.REACT_APP_ReferralShortURL, p, mgmCode, languageCode]);
                }
            }
    }
}

const getEsShorternUrl = (languageCode, mgmCode) => {
    var esLink = "https://hkbnes.net/ref";
    return formatString("{0}/{1}/{2}", [esLink, languageCode, mgmCode]);
}

const formatString = (input, replacements) => {
    var output = input;
    replacements.map((replacement, index) => {
        var currentIndexIndicator = `{${index}}`;
        while (output.includes(currentIndexIndicator)) {
            output = _.replace(output, currentIndexIndicator, replacement)
        }
    })
    return output;
}

export default replaceShareMessage;