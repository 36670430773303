import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import parseHtml from "react-html-parser";
import EmailShareButton from "../../share-button/email";
import WhatsappShareButton from "../../share-button/whatsapp";
import FacebookShareButton from "../../share-button/facebook";
import NameCardShareButton from "../../share-button/namecard";
import _ from 'lodash';
import BannerShareButtons from "./share-buttons"

import * as ServiceAction from "../../../redux/actions/serviceAction";

import * as helper from "../../../helper";

import styles from "./index.module.css";

function Index(props) {
  const rewardOfferRef = useRef(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [stickHeader, setStickHeader] = useState(false);
  const [headerPosition, setHeaderPosition] = useState(0);

  const [services, setServices] = useState([]);
  const [rewardOffer, setRewardOffer] = useState([]);
  const [staticContent, setStaticContent] = useState(null);

  const [jwtPayload, setJWTPayload] = useState(null);

  const handleTabOnclick = index => {
    setActiveTabIndex(index);
    window.location.hash = "reward-offer";
  };

  const getRewardOfferJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryRewardOfferContents`,
      method: "get"
    });
    setRewardOffer(cmsJson.data.map(d => d.data));
  };

  const getRewardOfferServiceJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryRewardOfferServiceContents`,
      method: "get"
    });
    setServices(cmsJson.data);
  };

  const getCMSStaticContentJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryRewardOfferStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  useEffect(() => {
    getRewardOfferJson();
    getRewardOfferServiceJson();
    getCMSStaticContentJson();
  }, []);

  const getActiveTabData = () => {
    let activeServiceID = services[activeTabIndex] && services[activeTabIndex].id;
    let activeRewardOffer = rewardOffer.find(ro => ro.service.iv[0].id == activeServiceID);
    return activeRewardOffer;
  };

  useEffect(() => {
    switch (window.location.hash) {
      case "#reward-offer":
        scrollToReward();
        break;
    }
    if (_.startsWith(window.location.hash, "#reward-offer")) {
      scrollToReward();
    }
    if (services && services.length > 0 && _.startsWith(window.location.hash, "#reward-offer-")) {
      var serviceAbbv = _.replace(window.location.hash, "#reward-offer-", "");
      dispatchServiceType(serviceAbbv);
      scrollToReward();
    }
  }, [window.location.hash, activeTabIndex]);

  useEffect(() => {
    if (services && services.length > 0 && activeTabIndex >= 0) {
      dispatchServiceType(services[activeTabIndex].data.abbreviation.iv);
    }
  }, [activeTabIndex]);

  useEffect(() => {
    if (!_.isEmpty(props.state.service.serviceType)) {
      var activeTabIndex = _.findIndex(services, { 'data': { 'abbreviation': { 'iv': props.state.service.serviceType } } });
      if (activeTabIndex < 0) {
        activeTabIndex = 0;
      }
      setActiveTabIndex(activeTabIndex);
    }
  }, [props.state.service.serviceType])

  const handleScroll = event => {
    setStickHeader(rewardOfferRef.current.getBoundingClientRect().top < 0);
    if (rewardOfferRef.current.getBoundingClientRect().bottom < 105) {
      setHeaderPosition(rewardOfferRef.current.getBoundingClientRect().bottom - 105);
    } else {
      setHeaderPosition(0);
    }
  };

  // Init
  useEffect(() => {
    if (services && services.length > 0 && activeTabIndex >= 0) {
      if (_.startsWith(window.location.hash, "#reward-offer-")) {
        var serviceAbbv = _.replace(window.location.hash, "#reward-offer-", "");
        dispatchServiceType(serviceAbbv);
        scrollToReward();
      } else {
        dispatchServiceType(services[activeTabIndex].data.abbreviation.iv);
      }
    }
  }, [services])

  // Delay scrollTo so it may be more accurate
  const scrollToReward = () => {
    setTimeout(() => window.scrollTo({
      top: rewardOfferRef.current.offsetTop,
      left: 0,
      behavior: 'smooth'
    }),
      1000)

  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setJWTPayload(props.state.account.jwt && helper.parseJwt(props.state.account.jwt));
  }, [props.state.account.jwt]);

  const dispatchServiceType = (serviceType) => {
    props.dispatch(
      ServiceAction.updateValue({
        name: "serviceType",
        value: serviceType
      })
    );
  }

  return (
    <div
      id="reward-offer"
      className={`${styles["main-wrapper"]} ${stickHeader && styles["stick-header"]}`}
      ref={rewardOfferRef}
      style={{
        backgroundColor: `var(--${staticContent && staticContent.sectionBackgroundColor.iv[0].data.name.iv})`,
        color: `var(--${staticContent && staticContent.contentTextColor.iv[0].data.name.iv})`
      }}
    >
      <div className={styles["inner-wrapper"]}>
        <h2 style={{ color: `var(--${staticContent && staticContent.titleTextColor.iv[0].data.name.iv})`, top: `${headerPosition}px` }}>
          {staticContent && staticContent.title[props.state.languageCode]}
        </h2>
        <div className={styles["reward-wrapper"]}>
          <div className={styles["service-nav-wrapper"]} style={{ top: `${headerPosition + 60}px` }}>
            {services.map((service, index) => (
              <div
                key={index}
                className={`button ${styles["item-wrapper"]} ${index == activeTabIndex && styles["active"]}`}
                style={{
                  width: `calc(100% / ${services.length})`,
                  backgroundColor: `var(--${staticContent && staticContent.rewardOfferServiceTabBgColor.iv[0].data.name.iv})`,
                  color: `var(--${staticContent && staticContent.rewardOfferServiceTabTextColor.iv[0].data.name.iv})`
                }}
                onClick={() => handleTabOnclick(index)}
              >
                <style>
                  {`
                  .${styles["item-wrapper"]}.${styles["active"]}{
                    background-color: var(--${staticContent && staticContent.rewardOfferServiceTabBgColorOnSelect.iv[0].data.name.iv}) !important;
                    color: var(--${staticContent && staticContent.rewardOfferServiceTabTextColorOnSelect.iv[0].data.name.iv}) !important;
                  }
                `}
                </style>
                <div className={styles["icon-wrapper"]}>
                  <img src={index == activeTabIndex ? service.data.iconActive[props.state.languageCode][0].url : service.data.icon[props.state.languageCode][0].url} />
                </div>
                <div className={styles["name"]}>{index == activeTabIndex ? service.data.name[props.state.languageCode] : ''}</div>
              </div>
            ))}
            <div className={styles["selector-wrapper"]}>
              <div
                className={styles["selector"]}
                style={{
                  width: `calc(100% / ${services.length})`,
                  left: `calc(${activeTabIndex} * (100% / ${services.length}))`,
                  backgroundColor: `var(--${staticContent && staticContent.rewardOfferServiceTabLineColorOnSelect.iv[0].data.name.iv})`
                }}
              ></div>
            </div>
          </div>
          <div
            className={styles["reward-content-wrapper"]}
            style={{
              backgroundColor: `var(--${staticContent && staticContent.serviceSectionBackgroundColor.iv[0].data.name.iv})`,
              color: `var(--${staticContent && staticContent.serviceContentTextColor.iv[0].data.name.iv})`
            }}
          >
            {getActiveTabData() &&
              getActiveTabData().rewardServicePlan &&
              getActiveTabData().rewardServicePlan.iv.map((servicePlan, index) => (
                <div
                  className={styles["service-plan-wrapper"]}
                  key={index}
                  style={{
                    backgroundColor: `var(--${staticContent && staticContent.servicePlanSectionBgColor.iv[0].data.name.iv})`,
                    color: `var(--${staticContent && staticContent.servicePlanContentTextColor.iv[0].data.name.iv})`
                  }}
                >
                  <div className={styles["title-wrapper"]} key={index}>
                    <div className={styles["logo-wrapper"]}>
                      <img src={servicePlan.data.successIcon[props.state.languageCode][0].url} />
                    </div>
                    <div className={styles["right-wrapper"]}>
                      <div className={styles["title"]}>{servicePlan.data.successMessage[props.state.languageCode]}</div>
                      <div className={styles["service-plan-desc"]}>{parseHtml(servicePlan.data.servicePlanDesc[props.state.languageCode])}</div>
                    </div>
                  </div>
                  <div className={styles["reward"]}>
                    {servicePlan.data.rewardType[props.state.languageCode].map((rewardType, rewardTypeIndex) => (
                      <div
                        className={`${styles["reward-item-wrapper"]} ${styles["referrer-reward"]}`}
                        key={rewardTypeIndex}
                        style={{ backgroundColor: `var(--${staticContent && staticContent.servicePlanRewardBackgroundColor.iv[0].data.name.iv})` }}
                      >
                        <div className={styles["reward-item-inner-wrapper"]}>
                          <div className={styles["title-wrapper"]}>
                            <div className={styles["icon-wrapper"]}>
                              <img src={rewardType.icon[0].url} />
                            </div>
                            <div className={styles["title"]} style={{ color: `var(--${staticContent && staticContent.servicePlanRewardTitleTextColor.iv[0].data.name.iv})` }}>
                              {rewardType.title}
                            </div>
                          </div>
                          <div className={styles["reward-combination"]}>
                            {rewardType.rewardOfferServicePlanReward[0].data.reward[props.state.languageCode].map((reward, index) => (
                              <div key={index} className={styles["item-wrapper"]}>
                                <style>
                                  {`
                                    .${styles["reward-combination"]} .${styles["item-wrapper"]}:not(:last-child)::after{
                                      color: var(--${staticContent && staticContent.servicePlanRewardPlusIconColor.iv[0].data.name.iv}) !important;
                                    }
                                  `}
                                </style>
                                {reward.icon && (
                                  <div className={`${styles["item-inner-wrapper"]} ${reward.icon.length > 0 && styles["with-icon"]}`}>
                                    {reward.icon.length > 0 && (
                                      <div className={styles["icon-wrapper"]}>
                                        <img src={reward.icon[0].url} />
                                      </div>
                                    )}
                                    <div className={styles["html"]}>{parseHtml(reward.text)}</div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            { // desktop view
              getActiveTabData()
              && getActiveTabData().bannerList
              && getActiveTabData().bannerList[props.state.languageCode]
              && getActiveTabData().bannerList[props.state.languageCode].map((banner, bannerIndex) => (
                <div className={styles["reward-banner"]} key={bannerIndex}>
                  <img src={banner.banner[0].url} />
                  {/* <SharePopper url={banner.banner[0].url} staticContent={staticContent} jwt={props.state.account.jwt} languageCode={props.state.languageCode}/> */}
                </div>
              ))}
            { // mobile view
            // testing
              getActiveTabData()
              && getActiveTabData().mobileBannerList
              && getActiveTabData().mobileBannerList[props.state.languageCode]
              && getActiveTabData().mobileBannerList[props.state.languageCode].map((banner, bannerIndex) => (
                <div className={styles["mobile-reward-banner"]} key={bannerIndex}>
                  <img src={banner.banner[0].url} />
                  {/* <SharePopper url={banner.banner[0].url} staticContent={staticContent} jwt={props.state.account.jwt} languageCode={props.state.languageCode}/> */}
                </div>
              ))}
            { // tablet view
              getActiveTabData()
              && getActiveTabData().tabletBannerList
              && getActiveTabData().tabletBannerList[props.state.languageCode]
              && getActiveTabData().tabletBannerList[props.state.languageCode].map((banner, bannerIndex) => (
                <div className={styles["tablet-reward-banner"]} key={bannerIndex}>
                  <img src={banner.banner[0].url} />
                  {/* <SharePopper url={banner.banner[0].url} staticContent={staticContent} jwt={props.state.account.jwt} languageCode={props.state.languageCode}/> */}
                </div>
              ))}
            <div className={styles["tnc"]}>{parseHtml(getActiveTabData() && getActiveTabData().tnc[props.state.languageCode])}</div>
            <div className={styles["share-buttons-wrapper"]}>
              <div className={styles["text-share"]}>{staticContent && staticContent.shareText[props.state.languageCode]}</div>
              <FacebookShareButton icon={staticContent && staticContent.btnShareFacebookIcon.iv[0].url} mgmCode={jwtPayload && jwtPayload.MgmCode} backgroundColor={staticContent && staticContent.btnShareFacebookBackgroundColor.iv[0].data.colorCode.iv} />
              <WhatsappShareButton icon={staticContent && staticContent.btnShareWhatsappIcon.iv[0].url} mgmCode={jwtPayload && jwtPayload.MgmCode} backgroundColor={staticContent && staticContent.btnShareWhatsappBackgroundColor.iv[0].data.colorCode.iv} />
              <EmailShareButton icon={staticContent && staticContent.btnShareEmailIcon.iv[0].url} mgmCode={jwtPayload && jwtPayload.MgmCode} backgroundColor={staticContent && staticContent.btnShareEmailBackgroundColor.iv[0].data.colorCode.iv} />
              <NameCardShareButton icon={staticContent && staticContent.btnShareNamecardIcon.iv[0].url} mgmCode={jwtPayload && jwtPayload.MgmCode} backgroundColor={staticContent && staticContent.btnShareNamecardBackgroundColor.iv[0].data.colorCode.iv} showDefault={false} />
            </div>
          </div>
        </div>
        <Link className={"a-remove-default-style"} to={`${props.match.url}${"/tnc"}${props.state.service.serviceType && _.isEmpty(props.state.service.serviceType) === false ? "#" + props.state.service.serviceType : ""}`}>
          <div className={`button ${styles["btn-tnc"]}`} style={{ color: `var(--${staticContent && staticContent.btnTncTextColor.iv[0].data.name.iv})` }}>
            {staticContent && staticContent.btnTnc[props.state.languageCode]}
          </div>
        </Link>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account,
        service: state.service
      }
    };
  })(Index)
);
