import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import styles from "./share-buttons.module.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

function ShareButtons(props) {
  const [open, setOpen] = useState(false);

  const getShareButtonIcon = () => {
    return open ? props.staticContent.shareIconOnClick.iv[0].url : props.staticContent.shareIcon.iv[0].url;
  };

  const handleClickShareButton = async platform => {
    let url = await getRedirectUrlRequest(props.mgmCode, props.redirectCode.iv, platform);
    window.open(url);
  };

  const getRedirectUrlRequest = async (code, redirectCode, platform) => {
    try {
      let platformRoute = "email";
      switch (platform) {
        case "whatsapp":
        case "facebook":
        case "email":
          platformRoute = platform;
          break;
      }
      //languageCode=${props.match.params.lang}
      let routeMGMCode = code;
      if (code == null) {
        routeMGMCode = "";
      }
      return process.env.REACT_APP_Api_Url + "/share/Plan/" + platformRoute + "/" + redirectCode + "/" + routeMGMCode + "?languageCode=" + props.match.params.lang;
    } catch (err) {}
  };

  return (
    <div className={`${styles["main-wrapper"]}`}>
      {props.mgmCode != null ? (
        <div
          className={`button ${styles["btn-share"]} ${open && styles["open"]}`}
          onClick={() => setOpen(!open)}
          style={{
            backgroundColor: `var(--${props.staticContent && props.staticContent.shareButtonBackgroundColor.iv[0].data.name.iv})`
          }}
        >
          <img src={props.staticContent && getShareButtonIcon()} />
        </div>
      ) : null}
      {open && (
        <div
          className={`${styles["share-buttons-wrapper"]}`}
          style={{
            backgroundColor: `var(--${props.staticContent && props.staticContent.shareDropdownBackgroundColor.iv[0].data.name.iv})`,
          }}
        >
        <style>
          {`
            .${styles["share-buttons-wrapper"]}::before{
              border-color: transparent transparent ${`var(--${props.staticContent && props.staticContent.shareDropdownBackgroundColor.iv[0].data.name.iv})`} transparent  !important
            }
          `}
        </style>
          <div className={`button ${styles["btn-share-social-media"]}`}>
            <img src={props.staticContent && props.staticContent.shareWhatsappIcon.iv[0].url} onClick={() => handleClickShareButton("whatsapp")} />
          </div>
          <div className={`button ${styles["btn-share-social-media"]}`}>
            <img src={props.staticContent && props.staticContent.shareFacebookIcon.iv[0].url} onClick={() => handleClickShareButton("facebook")} />
          </div>
          <div className={`button ${styles["btn-share-social-media"]}`}>
            <img src={props.staticContent && props.staticContent.shareEmailIcon.iv[0].url} onClick={() => handleClickShareButton("email")} />
          </div>
          <CopyToClipboard text={`${props.planUrl[props.state.languageCode].replace("{0}", props.mgmCode != null ? props.mgmCode : "")}`}>
            <div className={`button ${styles["btn-share-social-media"]}`}>
              <img src={props.staticContent && props.staticContent.shareCopyIcon.iv[0].url} />
            </div>
          </CopyToClipboard>
        </div>
      )}
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(ShareButtons)
);
