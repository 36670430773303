import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./index.module.css";

import GetReferralCode from "./get-referral-code";
import Login from "./login";
import GetPlan from "./get-plan";

function Account(props) {
  const accountRef = useRef(null);
  const [userType, setUserType] = useState("referrer");

  const [checkPromoCodeLoading, setCheckPromoCodeLoading] = useState(false);
  const [checkPromoCodeCode, setCheckPromoCodeCode] = useState(null);
  const [checkPromocodeErrorMessage, setCheckPromocodeErrorMessage] = useState(null);

  const [staticContent, setStaticContent] = useState(null);

  const checkPromoCode = async () => {
    if (checkPromoCodeCode) {
      let valid = await checkPromoCodeRequest(checkPromoCodeCode);
      if (valid) {
        window.open(`https://www.hkbn.net/personal/Redirect/Landing/Mobile/tc/${checkPromoCodeCode}`);
      } else {
        setCheckPromocodeErrorMessage(staticContent.checkCodeInvalidCode[props.state.languageCode]);
      }
    }
  };

  const checkPromoCodeRequest = async code => {
    if (checkPromoCodeLoading) {
      setCheckPromoCodeLoading(true);
      try {
        let checkPromoCodeResponse = await axios({
          url: `${process.env.REACT_APP_Api_Url}/referral/check-promo-code?code=${code}`,
          method: "get"
        });
        setCheckPromoCodeLoading(false);
        return checkPromoCodeResponse.status == 200;
      } catch (err) {
        setCheckPromoCodeLoading(false);
        return false;
      }
    }
  };

  const getCMSStaticContentJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryAccountStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  useEffect(() => {
    getCMSStaticContentJson();
  }, []);

  const referrerDisplay = () => {
    return (
      <Fragment>
        <GetReferralCode staticContent={staticContent} />
        <Login staticContent={staticContent} />
      </Fragment>
    );
  };

  const refereeDisplay = () => {
    return <GetPlan staticContent={staticContent} />;
  };

  const getContentDisplay = ut => {
    switch (ut) {
      case "referrer":
        return referrerDisplay();
      case "referee":
        return refereeDisplay();
    }
  };

  useEffect(() => {
    switch (window.location.hash) {
      case "#account":
        window.scrollTo(0, accountRef.current.offsetTop);
        break;
    }
  });

  return (
    <div
      id="account"
      className={styles["main-wrapper"]}
      ref={accountRef}
      style={{
        color: `var(--${staticContent && staticContent.contentTextColor.iv[0].data.name.iv})`,
        backgroundColor: `var(--${staticContent && staticContent.sectionBackgroundColor.iv[0].data.name.iv})`
      }}
    >
      <div className={styles["inner-wrapper"]}
      style={{
        backgroundColor: `var(--${staticContent && staticContent.innerWrapperBackgroundColor.iv[0].data.name.iv})`
      }}>
        <div className={styles["content-wrapper"]}>
          <div className={styles["user-type-selector-wrapper"]}>
          <style>
            {`
              .${styles["selector-inner-wrapper"]}  {
                box-shadow: 10px 10px 10px var(--${staticContent && staticContent.tabButtonSectionShadowColor.iv[0].data.name.iv});
                }

            `}
          </style>
            <div className={styles["selector-inner-wrapper"]} >
              <div
                className={`button ${styles["btn-wrapper"]} ${styles["btn-referrer"]} ${userType == "referrer" ? styles["selected"] : ""}`}
                onClick={() => setUserType("referrer")}
                style={{
                  backgroundColor: `var(--${staticContent && staticContent.btnReferrerBackgroundColor.iv[0].data.name.iv})`
                }}
              >
                <style>
                  {`
                    .${styles["btn-referrer"]} .${styles["icon-wrapper"]} {
                        background-image: url(${staticContent && staticContent.btnReferrerIcon.iv[0].url});
                    }

                    .${styles["btn-referrer"]}.${styles["selected"]} .${styles["icon-wrapper"]} {
                      background-image: url(${staticContent && staticContent.btnReferrerIconOnSelect.iv[0].url});
                    }

                    .${styles["btn-referrer"]}:hover .${styles["icon-wrapper"]} {
                      background-image: url(${staticContent && staticContent.btnReferrerIconOnSelect.iv[0].url});
                    }

                    .${styles["btn-referrer"]}:hover, .${styles["btn-referrer"]}.${styles["selected"]}{
                      background-color: var(--${staticContent && staticContent.btnReferrerBackgroundColorOnSelect.iv[0].data.name.iv}) !important;
                    }
                  `}
                </style>
                <div className={`${styles["icon-wrapper"]}`}></div>
                <div className={styles["title"]}>{staticContent && staticContent.btnReferrerText[props.state.languageCode]}</div>
              </div>
              <div
                className={`button ${styles["btn-wrapper"]} ${styles["btn-referee"]} ${userType == "referee" ? styles["selected"] : ""}`}
                onClick={() => setUserType("referee")}
                style={{
                  backgroundColor: `var(--${staticContent && staticContent.btnRefereeBackgroundColor.iv[0].data.name.iv})`
                }}
              >
                <style>
                  {`
                    .${styles["btn-referee"]} .${styles["icon-wrapper"]} {
                        background-image: url(${staticContent && staticContent.btnRefereeIcon.iv[0].url});
                    }

                    .${styles["btn-referee"]}.${styles["selected"]} .${styles["icon-wrapper"]} {
                      background-image: url(${staticContent && staticContent.btnRefereeIconOnSelect.iv[0].url});
                    }

                    .${styles["btn-referee"]}:hover .${styles["icon-wrapper"]} {
                      background-image: url(${staticContent && staticContent.btnRefereeIconOnSelect.iv[0].url});
                    }

                    .${styles["btn-referee"]}:hover, .${styles["btn-referee"]}.${styles["selected"]}{
                      background-color: var(--${staticContent && staticContent.btnRefereeBackgroundColorOnSelect.iv[0].data.name.iv}) !important;
                    }
                  `}
                </style>
                <div className={styles["icon-wrapper"]}></div>
                <div className={styles["title"]}>{staticContent && staticContent.btnRefereeText[props.state.languageCode]}</div>
              </div>
            </div>
          </div>
          <div className={styles["form-wrapper"]}>
            <style>
              {`
                .${styles["form-wrapper"]} > div::after{
                  content: "${staticContent && staticContent.textOr[props.state.languageCode]}";
                  background: var(--${staticContent && staticContent.innerWrapperBackgroundColor.iv[0].data.name.iv})
                }
              `}
            </style>
            {getContentDisplay(userType)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(Account)
);
