import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import * as cookie from "../../../cookie";
import * as helper from "../../../helper";
import styles from "./login.module.css";

import * as AccountAction from "../../../redux/actions/accountAction";

import { fireEvent } from "../../Tealium/TealiumHelper";

function Account(props) {
  const loginRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [requireRecaptcha, setRequireRecaptcha] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const loginForm = useForm();

  const loginFormHandleSubmit = async data => {
    await loginRequest({
      ...data,
      requireRecaptcha,
      GRecaptchaResponse: requireRecaptcha ? recaptchaResponse : null
    });
  };

  const loginRequest = async requestData => {
    if (!loading) {
      setLoading(true);
      try {
        let loginResponse = await axios({
          url: `${process.env.REACT_APP_Api_Url}/account/login`,
          method: "post",
          data: requestData
        });
        props.dispatch(
          AccountAction.updateValue({
            name: "jwt",
            value: loginResponse.data.jwt
          })
        );
        cookie.setCookie("jwt_token", loginResponse.data.jwt, helper.parseJwt(loginResponse.data.jwt).exp);
        setLoading(false);
        // Tealium Login Event Tracking
        // fireEvent('link', {
        //   event_name: 'refer_login',
        //   ga_event_category: 'refer_login',
        //   ga_event_label: 'hkbnacc',
        //   ga_event_action: 'login',
        //   pps: requestData.pps,
        // })
      } catch (err) {
        console.log(err.response.status)
        switch (err.response.status) {
          case 400:
            loginForm.setError("password", "loginError", props.staticContent && props.staticContent.loginError[props.state.languageCode]);
            loginForm.setError("customerID", "loginError", props.staticContent && props.staticContent.loginError[props.state.languageCode]);
            break;
          case 401:
            loginForm.setError("password", "loginError", props.staticContent && props.staticContent.loginError[props.state.languageCode]);
            loginForm.setError("customerID", "loginError", props.staticContent && props.staticContent.loginError[props.state.languageCode]);
            // setRequireRecaptcha(err.response.data.requireCaptcha);
            setRequireRecaptcha(true);
            break;
          case 429:
            loginForm.setError("customerID", "loginError", props.staticContent && props.staticContent.loginTooManyAttemptError[props.state.languageCode]);
            // setRequireRecaptcha(err.response.data.requireCaptcha);
            setRequireRecaptcha(true);
            break;
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    switch (window.location.hash) {
      case "#login":
        window.scrollTo(0, loginRef.current.offsetTop);
        break;
    }
  }, [window.location.hash]);

  return (
    <div
      className={styles["login-wrapper"]}
      ref={loginRef}
    >
      <div className={styles["login-inner-wrapper"]}>
        <h4>{props.staticContent && props.staticContent.loginTitle[props.state.languageCode]}</h4>
        <h5>{props.staticContent && props.staticContent.loginSubtitle[props.state.languageCode]}</h5>
        <style>
          {`
            .${styles["error-message"]}  {
              color: var(--${props.staticContent && props.staticContent.loginErrorTextColor.iv[0].data.name.iv}) !important; 
              }
          `}
        </style>
        <form onSubmit={loginForm.handleSubmit(loginFormHandleSubmit)}>
          <input
            type="text"
            autoComplete="off"
            className={`textbox ${styles["txt-pps"]}`}
            placeholder={props.staticContent && props.staticContent.txtPPSPlaceHolder[props.state.languageCode]}
            name="customerID"
            disabled={loading}
            ref={loginForm.register({ required: { value: true, message: props.staticContent && props.staticContent.loginRequirePPS[props.state.languageCode] } })}
          />
          <div className={styles["error-message"]}>{loginForm.errors["customerID"] && loginForm.errors["customerID"].message}</div>
          <input
            type={(props.staticContent && props.staticContent.hidePhoneInput && props.staticContent.hidePhoneInput.iv) == false ? "text" : "password"}
            autoComplete="off"
            className={`textbox ${styles["txt-phone"]}`}
            placeholder={props.staticContent && props.staticContent.txtPhonePlaceHolder[props.state.languageCode]}
            name="password"
            disabled={loading}
            ref={loginForm.register({ required: { value: true, message: props.staticContent && props.staticContent.loginRequireContact[props.state.languageCode] } })}
          />
          <div className={styles["error-message"]}>{loginForm.errors["password"] && loginForm.errors["password"].message}</div>
          {requireRecaptcha && <ReCAPTCHA sitekey={process.env.REACT_APP_RecaptchaKey} onChange={v => setRecaptchaResponse(v)} />}

          {
            (props.staticContent && props.staticContent.enableForgetAccount && props.staticContent.enableForgetAccount.iv) == true &&
            (
              <a className={"a-remove-default-style"} href={`${props.staticContent && props.staticContent.forgetAccountUrl && props.staticContent.forgetAccountUrl[props.state.languageCode]}`} target="_blank">
                <div className={`button ${styles["btn-forget-account"]}`}>{props.staticContent && props.staticContent.btnForgetAccount[props.state.languageCode]}</div>
              </a>
            )

          }
          <button className={`button ${styles["btn-login"]}`} type="submit" style={{ color: `var(--${props.staticContent && props.staticContent.btnLoginTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${props.staticContent && props.staticContent.btnLoginBackgroundColor.iv[0].data.name.iv})` }}>
            {props.staticContent && props.staticContent.btnLogin[props.state.languageCode]}
          </button>
        </form>
        {
          (props.staticContent && props.staticContent.enableFacebookLogin && props.staticContent.enableFacebookLogin.iv) == true &&
          (
            <a className={"a-remove-default-style"} href={`${props.staticContent && props.staticContent.facebookLoginUrl && props.staticContent.facebookLoginUrl[props.state.languageCode]}`}>
              <div className={`button ${styles["btn-fb-login"]}`}>{props.staticContent && props.staticContent.btnFacebookLogin[props.state.languageCode]}</div>
            </a>
          )
        }
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(Account)
);
