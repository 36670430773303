import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Swiper from "react-id-swiper";
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm";
import "react-id-swiper/src/styles/css/swiper.css";
import dayjs from "dayjs";

import styles from "./tacital-banner.module.css";
import { isUndefined } from "util";

function TacticalBanner(props) {
  const tacticalRef = useRef(null);
  const [tacticalOfferList, setTacticalOfferList] = useState([]);
  const [staticContent, setStaticContent] = useState(null);
  const [swiperOption, setSwiperOption] = useState(null);
  
  useEffect(()=>{
    setSwiperOption({
      modules: [Pagination, Navigation],
      pagination: {
        el: `.swiper-pagination.${styles["custom-swiper-pagination"]}`,
        bulletClass: `swiper-pagination-bullet`,
        bulletActiveClass: `${styles["custom-swiper-pagination-bullet-active"]}`,
        renderBullet: function(index, className) {
          return `<span class="${className} ${styles["custom-swiper-pagination-bullet"]}"></span>`;
        },
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: `.swiper-button-next.${styles["swiper-button-next"]}.${styles["swiper-control-button"]}`,
        prevEl: `.swiper-button-prev.${styles["swiper-button-prev"]}.${styles["swiper-control-button"]}`
      },
      loop: staticContent && staticContent.enableAutoScroll && staticContent.enableAutoScroll.iv,
      autoplay: {
        delay: staticContent && staticContent.autoScrollInterval && parseInt(staticContent.autoScrollInterval.iv * 1000)
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      containerClass: `swiper-container ${styles["swiper-container"]}`,
      wrapperClass: `swiper-wrapper`,
      rebuildOnUpdate: true
    });
  }, [staticContent]);


  useEffect(() => {
    switch (window.location.hash) {
      case "#tactical":
        window.scrollTo(0, tacticalRef.current.offsetTop);
        break;
    }
  });

  const getCMSStaticContentJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryTacticalOfferStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  const getCMSJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryOrderingContents`,
      method: "get"
    });
    setTacticalOfferList(cmsJson.data.map(d => d.data)[0].tacticalOffer.iv.map(d => d.data));
  };

  
  const onClickTacitalBanner = (url) => {
    if(url && url != null && url != undefined && url != "null" && url != "undefined"){
      window.open(url);
    }
    };

  useEffect(() => {
    getCMSJson();
    getCMSStaticContentJson();
  }, []);

  return (
    <div id="tactical" className={styles["main-wrapper"]} ref={tacticalRef}>
      <div className={styles["inner-wrapper"]}>
        <Swiper {...swiperOption}>
          {tacticalOfferList.map((banner, i) => (
            <div key={i} className={`noselect tactical-banner-responsive-background-img-${i} ${styles["banner-wrapper"]}`} onClick={() => {onClickTacitalBanner(`${banner && banner.link && banner.link[props.state.languageCode]}`)}}>
              <style>
                {`
                    .tactical-banner-responsive-background-img-${i}{
                        background-image: url(${banner.desktopBanner[props.state.languageCode][0].url})
                    }
                    @media screen and (max-width: 1024px) {
                      .tactical-banner-responsive-background-img-${i}{
                        background-image: url(${banner.tabletBanner[props.state.languageCode][0].url})
                      } 
                    }
                    @media screen and (max-width: 640px) {
                      .tactical-banner-responsive-background-img-${i}{
                        background-image: url(${banner.mobileBanner[props.state.languageCode][0].url})
                      } 
                    }
                    .${styles["custom-swiper-pagination-bullet"]} {
                      background: var(--${staticContent && staticContent.bulletColor.iv[0].data.name.iv});
                    }
                    
                    .${styles["custom-swiper-pagination-bullet-active"]} {
                      background: var(--${staticContent && staticContent.bulletColorOnSelect.iv[0].data.name.iv});
                    }
                  `}
              </style>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(TacticalBanner)
);
