import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as cookie from "../../cookie";
import * as helper from "../../helper";

import * as AccountAction from "../../redux/actions/accountAction";
import _ from 'lodash'

function Index(props) {
  // const [jwtLoaded, setJwtLoaded] = useState(false);

  const loadJWT = () => {
    // setJwtLoaded(false);

    let cookieJWT = cookie.getCookie("jwt_token");
    let jwt = null;
    if (window.jwt) {
      jwt = window.jwt;
    } else if (cookieJWT) {
      jwt = cookieJWT;
    }

    if (jwt) {
      props.dispatch(
        AccountAction.updateValue({
          name: "jwt",
          value: jwt
        })
      );
      cookie.setCookie("jwt_token", jwt, helper.parseJwt(jwt).exp);
    }

    if (_.isEmpty(window.location.hash)) {
      props.history.push(`/${props.match.params.lang}`);
    }
    else {
      props.history.push(`/${props.match.params.lang}` + window.location.hash);
    }

    // setJwtLoaded(true);
  };

  useEffect(() => {
    loadJWT();
  }, []);

  // useEffect(() => {
  //   if (jwtLoaded === true) {
  //     switch (window.location.hash) {
  //       case "#hist":
  //         window.location.href = window.location.origin + "/portal/tc/referral-history";
  //         break;
  //     }
  //   }
  // }, [window.location.hash, jwtLoaded]);

  return <div>Loading...</div>;
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode
      }
    };
  })(Index)
);
