import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import parseHtml from "react-html-parser";
import Helmet from "react-helmet";
import dayjs from "dayjs";
import dayjsHandler from "../../dayjsHandler";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import * as helper from "../../helper";

import ReferralHistoryRow from "./row";

import styles from "./index.module.css";

const dayjsInstance = dayjsHandler.getInstance();

function ReferralRecord(props) {
  const dayPickerToRef = useRef(null);
  const [staticContent, setStaticContent] = useState(null);
  const [rewardMapping, setRewardMapping] = useState([]);
  const [jwtPayload, setJWTPayload] = useState(null);

  const [filterFrom, setFilterFrom] = useState(dayjs().subtract(1, "year").$d.$d);
  const [filterTo, setFilterTo] = useState(dayjs().$d.$d);
  const [orderBy, setOrderBy] = useState(null);

  const [noOfRecordPerPage, setNoOfRecordPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [modifiers, setModifiers] = useState({
    start: filterFrom,
    end: filterTo
  });

  const [referralHistory, setReferralHistory] = useState([]);

  const getStatusIcon = status => {
    switch (status.toLowerCase()) {
      case "pending":
      case "p":
        return (
          <div className={`${styles["pending"]} ${styles["status-icon"]}`} style={{backgroundColor: `var(--${staticContent && staticContent.tableStatusPendingIconBgColor.iv[0].data.name.iv})`}}>
            <img src={staticContent && staticContent.tableStatusPendingIcon.iv[0].url} />
          </div>
        );
      case "fail":
      case "f":
        return (
          <div className={`${styles["fail"]} ${styles["status-icon"]}`} style={{backgroundColor: `var(--${staticContent && staticContent.tableStatusFailIconBgColor.iv[0].data.name.iv})`}}>
            <img src={staticContent && staticContent.tableStatusFailIcon.iv[0].url} />
          </div>
        );
      case "success":
      case "s":
        return (
          <div className={`${styles["success"]} ${styles["status-icon"]}`} style={{backgroundColor: `var(--${staticContent && staticContent.tableStatusSuccessIconBgColor.iv[0].data.name.iv})`}}>
            <img src={staticContent && staticContent.tableStatusSuccessIcon.iv[0].url} />
          </div>
        );
    }
  };

  // const getCMSStaticContentJson = async () => {
  //   if (props.state.jsonMapping.queryReferralHistoryStaticContentContents) {
  //     let cmsStaticContentJson = await axios({
  //       method: "get",
  //       url: props.state.jsonMapping.queryReferralHistoryStaticContentContents.StaticJsonFile.CDNUrl
  //     });
  //     setStaticContent(cmsStaticContentJson.data.map(d => d.data)[0]);
  //     setOrderBy(cmsStaticContentJson.data.map(d => d.data)[0].sortBy[props.state.languageCode][0].type);
  //   }
  // };

  // const getReferralHistoryRewardMappingJson = async () => {
  //   if (props.state.jsonMapping.queryReferralHistoryRewardMappingContents) {
  //     let rewardMappingJson = await axios({
  //       method: "get",
  //       url: props.state.jsonMapping.queryReferralHistoryRewardMappingContents.StaticJsonFile.CDNUrl
  //     });
  //     setRewardMapping(rewardMappingJson.data.map(d => d.data));
  //   }
  // };

  const getCMSStaticContentJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryReferralHistoryStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
    setOrderBy(cmsJson.data.map(d => d.data)[0].sortBy[props.state.languageCode] && cmsJson.data.map(d => d.data)[0].sortBy[props.state.languageCode][0].type);
  };

  const getReferralHistoryRewardMappingJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryReferralHistoryRewardMappingContents`,
      method: "get"
    });
    setRewardMapping(cmsJson.data.map(d => d.data));
  };

  useEffect(() => {
    getCMSStaticContentJson();
    getReferralHistoryRewardMappingJson();
  }, []);

  const getReferralHistoryRequest = async (orderStartDate, orderEndDate) => {
    try {
      let referralHistoryResponse = await axios({
        method: "get",
        url: `${process.env.REACT_APP_Api_Url}/referral/get-referral-order?orderStartDate=${orderStartDate}&orderEndDate=${orderEndDate}`,
        headers: {
          Authorization: `Bearer ${props.state.account.jwt}`
        }
      });

      setReferralHistory(referralHistoryResponse.data);
    } catch (err) {}
  };

  const getReferralHistory = async () => {
    getReferralHistoryRequest(dayjs(filterFrom).format("YYYY-MM-DD"), dayjs(filterTo).format("YYYY-MM-DD"));
  };

  const getSortedReferralHistory = () => {
    referralHistory.sort((a, b) => {
      switch (orderBy) {
        case "referralDateDesc":
          return new Date(b.orderDate) - new Date(a.orderDate);
        case "referralDateAsc":
          return new Date(a.orderDate) - new Date(b.orderDate);
      }
    });
    return referralHistory;
  };

  const getPagingReferralHistory = () => {
    let pageFirstRecord = (currentPage - 1) * noOfRecordPerPage;
    return getSortedReferralHistory().slice(pageFirstRecord, pageFirstRecord + noOfRecordPerPage);
  };

  const getPaging = () => {
    let pageNoArray = [];
    for (let i = 0; i < referralHistory.length / noOfRecordPerPage; i++) {
      pageNoArray.push(i + 1);
    }
    return pageNoArray;
  };

  // useEffect(() => {
  //   getCMSStaticContentJson();
  //   getReferralHistoryRewardMappingJson();
  // }, [props.state.jsonMapping]);

  useEffect(() => {
    setJWTPayload(props.state.account.jwt && helper.parseJwt(props.state.account.jwt));

    if (props.state.account.jwt != null) {
      getReferralHistory();
    }
  }, [props.state.account.jwt]);

  return (
    <div className={styles["main-wrapper"]} style={{color: `var(--${staticContent && staticContent.contentTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${staticContent && staticContent.sectionBackgroundColor.iv[0].data.name.iv})`}}>
      <div className={styles["inner-wrapper"]}>
        <style>
              {`
                .${styles["btn-icon"]}  {
                  color: var(--${staticContent && staticContent.buttonBackHyperlinkTextColor.iv[0].data.name.iv});
                }
              `}
        </style>
        <Link to={`/${props.match.params.lang}`} className={`a-remove-default-style ${styles["btn-back"]}`} style={{color: `var(--${staticContent && staticContent.buttonBackHyperlinkTextColor.iv[0].data.name.iv})`}}>
          <FontAwesomeIcon icon={faChevronLeft} className={`${styles["back-icon"]}`} />
          {staticContent && staticContent.btnBack[props.state.languageCode]}
        </Link>
        <div className={styles["referral-record-wrapper"]}>
          <div className={styles["header-wrapper"]}>
            <h3>{staticContent && staticContent.referralRecordTitle[props.state.languageCode]}</h3>
            <div className={styles["desc"]}>{staticContent && parseHtml(staticContent.referralRecordDescription[props.state.languageCode])}</div>
            <div className={styles["filter-wrapper"]}>
              <div className={styles["sort-wrapper"]}>
                {staticContent && (
                  <select onChange={e => setOrderBy(e.target.value)}>
                    {staticContent.sortBy[props.state.languageCode] &&
                      staticContent.sortBy[props.state.languageCode].map(o => {
                        return (
                          <option value={o.type} key={o.type}>
                            {o.text}
                          </option>
                        );
                      })}
                  </select>
                )}
              </div>
              <div className={`input-from-to ${styles["date-filter-wrapper"]}`}>
                {staticContent && staticContent.filterDateFrom[props.state.languageCode]}
                <div className={styles["date-picker-wrapper"]}>
                  <DayPickerInput
                    value={filterFrom}
                    placeholder=""
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      selectedDays: [filterFrom, { filterFrom, filterTo }],
                      disabledDays: {
                        before: dayjs().subtract(1, "year").$d.$d,
                        after: filterTo || dayjs().$d.$d
                      },
                      toMonth: filterTo,
                      modifiers,
                      numberOfMonths: 2,
                      onDayClick: () => dayPickerToRef.current.getInput().focus()
                    }}
                    onDayChange={from => setFilterFrom(from)}
                  />
                </div>
                {staticContent && staticContent.filterDateTo[props.state.languageCode]}
                <span className={`input-from-to-to ${styles["date-picker-wrapper"]}`}>
                  <DayPickerInput
                    ref={dayPickerToRef}
                    value={filterTo}
                    placeholder=""
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      selectedDays: [filterFrom, { filterFrom, filterTo }],
                      disabledDays: {
                        before: filterFrom || dayjs().subtract(1, "year").$d.$d,
                        after: dayjs().$d.$d
                      },
                      modifiers,
                      month: filterFrom,
                      fromMonth: filterFrom,
                      numberOfMonths: 2
                    }}
                    onDayChange={to => setFilterTo(to)}
                  />
                </span>
                <div className={`button ${styles["btn-apply"]}`} onClick={() => getReferralHistory()} style={{color: `var(--${staticContent && staticContent.btnFilterApplyTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${staticContent && staticContent.btnFilterApplyBgColor.iv[0].data.name.iv})`}}>
                  {staticContent && staticContent.btnFilterApply[props.state.languageCode]}
                </div>
                <Helmet>
                  <style>{`
                .input-from-to .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                  background-color: #f0f8ff !important;
                  color: #4a90e2 !important;
                }
                .input-from-to .DayPicker-Day {
                  border-radius: 0 !important;
                }
                .input-from-to .DayPicker-Day--start {
                  border-top-left-radius: 50% !important;
                  border-bottom-left-radius: 50% !important;
                }
                .input-from-to .DayPicker-Day--end {
                  border-top-right-radius: 50% !important;
                  border-bottom-right-radius: 50% !important;
                }
                .input-from-to .DayPickerInput-Overlay {
                  width: 550px;
                }
                .input-from-to-to .DayPickerInput-Overlay {
                  margin-left: -240px;
                }
                @media screen and (max-width: 640px){
                  .input-from-to-to .DayPickerInput-Overlay {
                    margin-left: 0px;
                  }
                }
              `}</style>
                </Helmet>
              </div>
            </div>
          </div>
          <div className={styles["table-wrapper"]}>
            <div className={styles["table-inner-wrapper"]}>
              <div className={`${styles["row"]} ${styles["header"]}`} style={{color: `var(--${staticContent && staticContent.tableContentTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${staticContent && staticContent.tableBackgroundColor.iv[0].data.name.iv})`}}>
                <div>{staticContent && staticContent.tableHeaderReferralDate[props.state.languageCode]}</div>
                <div>{staticContent && staticContent.tableHeaderRefereeName[props.state.languageCode]}</div>
                <div>{staticContent && staticContent.tableHeaderReward[props.state.languageCode]}</div>
                <div>{staticContent && staticContent.tableHeaderServiceAndStatus[props.state.languageCode]}</div>
                
              </div>
              {getPagingReferralHistory().map((r, index) => (
                <ReferralHistoryRow key={index} data={r} staticContent={staticContent} rewardMapping={rewardMapping} />
              ))}
            </div>
          </div>
          <div className={styles["bottom-wrapper"]}>
            <div className={styles["referral-status"]}>
              <div>{staticContent && staticContent.orderStatus[props.state.languageCode]}</div>
              <div className={styles["status-wrapper"]}>
                {getStatusIcon("success")}
                <span>{staticContent && staticContent.orderStatusSuccess[props.state.languageCode]}</span>
              </div>
              <div className={styles["status-wrapper"]}>
                {getStatusIcon("pending")}
                {staticContent && staticContent.orderStatusPending[props.state.languageCode]}
              </div>
              <div className={styles["status-wrapper"]}>
                {getStatusIcon("fail")}
                {staticContent && staticContent.orderStatusFail[props.state.languageCode]}
              </div>
            </div>
            <div className={styles["paging"]}>
              {currentPage > 1 && <FontAwesomeIcon icon={faChevronLeft} className={`button ${styles["btn-previous"]}`} onClick={() => setCurrentPage(currentPage - 1)} />}
              {getPaging().map((page, i) => (
                <div className={`button ${styles["btn-page"]} ${currentPage == page && styles["active"]}`} key={i} onClick={() => setCurrentPage(page)}>
                  {page}
                </div>
              ))}
              {currentPage < referralHistory.length / noOfRecordPerPage && (
                <FontAwesomeIcon icon={faChevronRight} className={`button ${styles["btn-next"]}`} onClick={() => setCurrentPage(currentPage + 1)} />
              )}
            </div>
          </div>
          <div className={styles["nc-order-desc"]}>{staticContent && staticContent.ncOrderDesc[props.state.languageCode]}</div>
          <Link to={`/${props.match.params.lang}`} className={`a-remove-default-style ${styles["button-back-wrapper"]}`}>
            <div className={styles["btn-back"]} style={{color: `var(--${staticContent && staticContent.btnBackTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${staticContent && staticContent.btnBackBgColor.iv[0].data.name.iv})`}}>{staticContent && staticContent.btnBack[props.state.languageCode]}</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account
      }
    };
  })(ReferralRecord)
);
