import React, { Component, Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { withRouter } from "react-router";

import RouteMain from "./Route-Main";
import LanguageNotFound from "./LanguageNotFound.js";

class RouteLanguage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/:lang(tc|en)" component={RouteMain} />
          <Route
            render={() => {
              return <LanguageNotFound />;
            }}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(RouteLanguage);
