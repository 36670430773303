import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Swiper from "react-id-swiper";
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm";
import "react-id-swiper/src/styles/css/swiper.css";
import styles from "./index.module.css";
import * as cookie from "../../../cookie";
import * as helper from "../../../helper";

import ShareButtons from "./share-buttons";

function Plan(props) {
  //const jwt_token = cookie.getCookie("jwt_token");
  var jwtPayload = null;
  var referralCode = null;
  if (props.state.account.jwt != null) {
    try {
      jwtPayload = helper.parseJwt(props.state.account.jwt);
      referralCode = jwtPayload.MgmCode;
    } catch (e) {}
  }
  const planRef = useRef(null);
  const [swiperOption] = useState({
    modules: [Navigation],
    // pagination: {
    //   el: `.swiper-pagination.${styles["custom-swiper-pagination"]}`,
    //   bulletClass: `swiper-pagination-bullet`,
    //   bulletActiveClass: `${styles["custom-swiper-pagination-bullet-active"]}`,
    //   renderBullet: function(index, className) {
    //     return `<span class="${className} ${
    //       styles["custom-swiper-pagination-bullet"]
    //     }"></span>`;
    //   },
    //   type: "bullets",
    //   clickable: true
    // },
    // loop: true,
    // autoplay: {
    //   delay: 5000
    // },
    navigation: {
      nextEl: `.swiper-button-next.${styles["swiper-button-next"]}.${styles["swiper-control-button-right"]}`,
      prevEl: `.swiper-button-prev.${styles["swiper-button-prev"]}.${styles["swiper-control-button-left"]}`
    },
    slidesPerView: 3,
    spaceBetween: 30,
    containerClass: `swiper-container ${styles["swiper-container"]}`,
    wrapperClass: `swiper-wrapper`,
    rebuildOnUpdate: true,
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      640: {
        slidesPerView: 1
      }
    }
  });

  const [planList, setPlanList] = useState([]);
  const [staticContent, setStaticContent] = useState(null);

  useEffect(() => {
    switch (window.location.hash) {
      case "#plan":
        window.scrollTo(0, planRef.current.offsetTop);
        break;
    }
  });

  const getCMSJson = async () => {
    let cmsJson = await axios({ 
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryPlanContents`,
      method: "get"
    });
    setPlanList(cmsJson.data.map(d => d.data));
  };

  const getCMSStaticContentJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryPlanStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  useEffect(() => {
    getCMSJson();
    getCMSStaticContentJson();
  }, []);

  return (
    <div
      id="plan"
      className={styles["main-wrapper"]}
      ref={planRef}
      style={{
        backgroundColor: `var(--${staticContent && staticContent.sectionBackgroundColor.iv[0].data.name.iv})`,
        color: `var(--${staticContent && staticContent.contentTextColor.iv[0].data.name.iv})`
      }}
    >
      <div className={styles["inner-wrapper"]}>
        <h2 style={{ color: `var(--${staticContent && staticContent.titleTextColor.iv[0].data.name.iv})` }}>{staticContent && staticContent.title[props.state.languageCode]}</h2>
        <Swiper {...swiperOption}>
          {planList.map((plan, index) => (
            <div key={index} className={`noselect ${styles["card-wrapper"]}`}>
              <div
                style={{
                  boxShadow: `10px 10px 10px var(--${staticContent && staticContent.cardShadowColor.iv[0].data.name.iv})`
                }}
              >
                <div className={styles["image-wrapper"]}>
                  <img src={plan.image[props.state.languageCode][0].url} />
                </div>
                <div
                  className={styles["content-wrapper"]}
                  style={{
                    backgroundColor: `var(--${staticContent && staticContent.cardBgColor.iv[0].data.name.iv})`
                  }}
                >
                  <div
                    className={styles["tag"]}
                    style={{
                      color: `var(--${staticContent && staticContent.tagColor.iv[0].data.name.iv})`
                    }}
                  >
                    {plan.tag[props.state.languageCode]}
                  </div>
                  <div className={styles["title"]}>{plan.title[props.state.languageCode]}</div>
                  <div className={styles["desc"]}>{plan.description[props.state.languageCode]}</div>
                  <div className={styles["buttons-wrapper"]}>
                    <a href={plan.planUrl[props.state.languageCode].replace("{0}", referralCode != null ? referralCode : "")}>
                      <div
                        className={styles["btn-more"]}
                        style={{
                          backgroundColor: `var(--${staticContent && staticContent.btnMoreBackgroundColor.iv[0].data.name.iv})`,
                          color: `var(--${staticContent && staticContent.btnMoreTextColor.iv[0].data.name.iv})`
                        }}
                      >
                        {staticContent && staticContent.btnMore[props.state.languageCode]}
                      </div>
                    </a>
                    {referralCode != null ? <ShareButtons staticContent={staticContent} redirectCode={plan.redirectCode} mgmCode={referralCode} planUrl={plan.planUrl} /> : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account
      }
    };
  })(Plan)
);
