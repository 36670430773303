import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Swiper from "react-id-swiper";
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm";
import "react-id-swiper/src/styles/css/swiper.css";
import parseHtml from "react-html-parser";
import Scrollchor from "react-scrollchor";
import _ from "lodash";
import * as ServiceAction from "../../redux/actions/serviceAction";

import styles from "./main-banner.module.css";

import * as UiInteractionAction from '../../redux/actions/uiInteractionAction'

function MainBanner(props) {
  const [mainBannerList, setMainBannerList] = useState([]);
  const [staticContent, setStaticContent] = useState(null);
  const [swiperOption, setSwipperOption] = useState(null);

  useEffect(() => {
    setSwipperOption({
      modules: [Pagination, Navigation],
      pagination: {
        el: `.swiper-pagination.${styles["custom-swiper-pagination"]}`,
        bulletClass: `swiper-pagination-bullet`,
        bulletActiveClass: `${styles["custom-swiper-pagination-bullet-active"]}`,
        renderBullet: function (index, className) {
          return `<span class="${className} ${styles["custom-swiper-pagination-bullet"]}"></span>`;
        },
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: `.swiper-button-next.${styles["swiper-button-next"]}.${styles["swiper-control-button"]}`,
        prevEl: `.swiper-button-prev.${styles["swiper-button-prev"]}.${styles["swiper-control-button"]}`
      },
      loop: staticContent && staticContent.enableAutoScroll && staticContent.enableAutoScroll.iv,
      autoplay: {
        delay: staticContent && staticContent.autoScrollInterval && parseInt(staticContent.autoScrollInterval.iv * 1000)
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      containerClass: `swiper-container ${styles["swiper-container"]}`,
      wrapperClass: `swiper-wrapper`,
      rebuildOnUpdate: true
    });
  }, [staticContent]
  );


  const beforeAnimateAnchorButton = (anchorTarget, onClickServiceType) => {

    props.dispatch(
      UiInteractionAction.updateDict({
        name: anchorTarget,
        value: Date.now()
      })
    );

    var serviceType = '';
    if (onClickServiceType &&
      onClickServiceType.length > 0 &&
      onClickServiceType[0].data &&
      onClickServiceType[0].data.abbreviation &&
      onClickServiceType[0].data.abbreviation.iv &&
      _.isEmpty(onClickServiceType[0].data.abbreviation.iv) === false) {
      serviceType = onClickServiceType[0].data.abbreviation.iv;
      props.dispatch(
        ServiceAction.updateValue({
          name: "serviceType",
          value: serviceType
        })
      );
    }

  };

  const bannerButton = (buttonConfig, index, dom) => {
    switch (buttonConfig.onClickAction) {
      case "url":
        return (
          <a href={buttonConfig.onClickUrl} className={`a-remove-default-style`} key={index}>
            {dom}
          </a>
        );
      case "anchor":
        return (
          <Scrollchor to={`${buttonConfig.onClickAnchor}`} className={`a-remove-default-style`} key={index} beforeAnimate={() => beforeAnimateAnchorButton(buttonConfig.onClickAnchor, buttonConfig.onClickAnchorServType)}>
            {dom}
          </Scrollchor>
        );
    }
  };


  const getCMSJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryOrderingContents`,
      method: "get"
    });
    setMainBannerList(cmsJson.data.map(d => d.data)[0].mainBanner.iv.map(d => d.data));
  };

  const getCMSStaticContentJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryMainBannerStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  useEffect(() => {
    getCMSJson();
    getCMSStaticContentJson();
  }, []);


  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["inner-wrapper"]}>
        <Swiper {...swiperOption}>
          {mainBannerList.map((banner, i) => (
            <div key={i} className={`noselect ${styles["banner-wrapper"]}`} >
              <div className={styles["left-wrapper"]} style={{ backgroundColor: `var(--${banner && banner.sectionBackgroundColor.iv[0].data.name.iv})`, color: `var(--${banner && banner.contentTextColor.iv[0].data.name.iv})` }} >
                <div className={`main-banner-responsive-background-img-${i} ${styles["image-wrapper"]}`}>
                  <style>
                    {`
                    .main-banner-responsive-background-img-${i}{
                        background-image: url(${banner.desktopBanner[props.state.languageCode][0].url})
                    }
                    @media screen and (max-width: 1024px) {
                      .main-banner-responsive-background-img-${i}{
                        background-image: url(${banner.tabletBanner[props.state.languageCode][0].url})
                      } 
                    }
                    @media screen and (max-width: 640px) {
                      .main-banner-responsive-background-img-${i}{
                        background-image: url(${banner.mobileBanner[props.state.languageCode][0].url})
                      } 
                    }
                    .${styles["custom-swiper-pagination-bullet"]} {
                      background: var(--${staticContent && staticContent.bulletColor.iv[0].data.name.iv});
                    }
                    
                    .${styles["custom-swiper-pagination-bullet-active"]} {
                      background: var(--${staticContent && staticContent.bulletColorOnSelect.iv[0].data.name.iv});
                    }
                  `}
                  </style>
                </div>
                {banner.enableMGMLogo.iv ? (
                  <div className={styles["mgm-logo-wrapper"]} style={{ backgroundColor: `var(--${banner.mgmLogoBackgroundColor && banner.mgmLogoBackgroundColor.iv[0].data.name.iv})` }}>
                    <img src={banner.mgmLogo[props.state.languageCode][0].url} />
                  </div>

                ) : (
                  <Fragment />
                )}
              </div>
              <div className={styles["right-wrapper"]} style={{ backgroundColor: `var(--${banner && banner.sectionBackgroundColor.iv[0].data.name.iv})`, color: `var(--${banner && banner.contentTextColor.iv[0].data.name.iv})` }} >
                <div className={styles["content-wrapper"]}>
                  <div className={styles["title"]}>{parseHtml(banner.title[props.state.languageCode])}</div>
                  <div className={styles["subtitle"]}>{parseHtml(banner.description[props.state.languageCode])}</div>
                  <div className={styles["promo-combination"]}>
                    {banner.promoCombination &&
                      banner.promoCombination[props.state.languageCode].map((item, index) => (
                        <div key={index} className={styles["item-wrapper"]}>
                          <div className={styles["image-wrapper"]} style={{ backgroundColor: `var(--${item && item.iconBackgroundColor[0].data.name.iv})` }} >
                            <img src={item.icon[0].url} />
                          </div>
                          <div className={styles["desc"]}>{item.name}</div>
                        </div>
                      ))}
                  </div>
                  <div className={styles["button-wrapper"]}>
                    {banner.buttons &&
                      banner.buttons[props.state.languageCode].map((button, index) =>
                        bannerButton(
                          button,
                          index,
                          <div
                            className={`button ${styles["button"]}`}
                            style={{
                              backgroundColor: `var(--${button.backgroundColor[0].data.name.iv})`,
                              color: `var(--${button.textColor[0].data.name.iv})`,
                              border: `1px solid var(--${button.borderColor[0].data.name.iv})`
                            }}
                          >
                            {button.text}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        uiInteraction: state.uiInteraction,
        service: state.service
      }
    };
  })(MainBanner)
);
