// Copy from ../Plan/share-buttons.js

import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import styles from "./share-buttons.module.css";
import _ from "lodash"

function BannerShareButtons(props) {
  const [open, setOpen] = useState(false);

  const getBannerShareButtonUrl = () => {
    return props.url;
  };

  const getShareButtonIcon = () => {
    return open ? props.staticContent.shareIconOnClick.iv[0].url : props.staticContent.shareIcon.iv[0].url;
  };

  const handleClickShareButton = async (platform, subService) => {
    if (_.isEmpty(props.mgmCode)) {
      console.log(`/${props.match.params.lang}#account`)
      props.history.push(`/${props.match.params.lang}#account`);
    } else {
      let url = await getRedirectUrlRequest(props.mgmCode, platform, subService);
      window.open(url);
    }
  };

  const getRedirectUrlRequest = async (code, platform, subService) => {
    try {
      let platformRoute = "email";
      switch (platform) {
        case "whatsapp":
        case "facebook":
        case "email":
          platformRoute = platform;
          break;
        case "namecard":
          break;
      }
      //languageCode=${props.match.params.lang}
      let routeMGMCode = code;
      if (code == null) {
        routeMGMCode = "";
      }
      // return process.env.REACT_APP_Api_Url + "/share/Plan/" + platformRoute + "/" + redirectCode + "/" + routeMGMCode + "?languageCode=" + props.match.params.lang;
      return `${process.env.REACT_APP_Api_Url}/share/customer/${platformRoute}?languageCode=${props.match.params.lang}&mgmCode=${code}&subServiceType=${subService}`;
    } catch (err) { }
  };

  return (
    <div className={`${styles["main-wrapper"]}`}>
      <img src={props.url && getBannerShareButtonUrl()} />
      <div
        className={`button ${styles["btn-share"]} ${open && styles["open"]}`}
        onClick={() => setOpen(!open)}
        style={{
          backgroundColor: `var(--${props.staticContent && props.staticContent.popperBgColor.iv[0].data.name.iv})`
        }}
      >
        <img src={props.staticContent && getShareButtonIcon()} />
      </div>
      {open && (
        <div
          className={`${styles["share-buttons-wrapper"]}`}
          style={{
            backgroundColor: `var(--${props.staticContent && props.staticContent.popperBgColor.iv[0].data.name.iv})`,
          }}
        >
          <style>
            {`
            .${styles["share-buttons-wrapper"]}::before{
              border-color: transparent transparent ${`var(--${props.staticContent && props.staticContent.popperBgColor.iv[0].data.name.iv})`} transparent  !important
            }
          `}
          </style>
          <div className={`button ${styles["btn-share-social-media"]}`}>
            <img src={props.staticContent && props.staticContent.btnShareWhatsappIcon.iv[0].url} onClick={() => handleClickShareButton("whatsapp", props.subService)} />
          </div>
          <div className={`button ${styles["btn-share-social-media"]}`}>
            <img src={props.staticContent && props.staticContent.btnShareFacebookIcon.iv[0].url} onClick={() => handleClickShareButton("facebook", props.subService)} />
          </div>
          <div className={`button ${styles["btn-share-social-media"]}`}>
            <img src={props.staticContent && props.staticContent.btnShareEmailIcon.iv[0].url} onClick={() => handleClickShareButton("email", props.subService)} />
          </div>
          <div className={`button ${styles["btn-share-social-media"]}`}>
            <img src={props.staticContent && props.staticContent.btnShareNamecardIcon.iv[0].url} onClick={() => handleClickShareButton("namecard", props.subService)} />
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(BannerShareButtons)
);
