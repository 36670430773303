import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import VideoSection from "./video-section";

import styles from "./index.module.css";

function Index(props) {
  const videoRef = useRef(null);
  const [staticContent, setStaticContent] = useState(null);
  const [expand, setExpand] = useState(false);
  const [expandManualTime, setExpandManualTime] = useState(false); // determine if the expand state should be tiggered according to manually clicking expand button
  
  useEffect(() => {
   var anchorClickVideoTime = props && props.state && props.state.uiInteraction && props.state.uiInteraction.manualClickAnchorTimeDict && props.state.uiInteraction.manualClickAnchorTimeDict['video'];
   

    if(window.location.hash == "#video"){       
      window.scrollTo(0, videoRef.current.offsetTop); 
      determineExpand();   
    }
    else if(anchorClickVideoTime > expandManualTime){
      window.scrollTo(0, videoRef.current.offsetTop); 
      determineExpand();   
    }

  }, [window.location.hash, props.state.uiInteraction.manualClickAnchorTimeDict && props.state.uiInteraction.manualClickAnchorTimeDict['video']]);


  const determineExpand = () =>{
    var anchorClickVideoTime = props && props.state && props.state.uiInteraction && props.state.uiInteraction.manualClickAnchorTimeDict && props.state.uiInteraction.manualClickAnchorTimeDict['video'];
    if(anchorClickVideoTime == undefined){
      if(window.location.hash == "#video" && expandManualTime == false){
        setExpand(true);
      }
    }
    else{
      if(anchorClickVideoTime > expandManualTime){
          setExpand(true);
      }
    }
  };

  const getCMSJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryVideoSectionStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  useEffect(() => {
    getCMSJson();
  }, []);

  const manualOnClickExpandButton = () => {
    setExpandManualTime(Date.now());
    setExpand(!expand);
    };

  return (
    <div
      id="video"
      className={styles["main-wrapper"]}
      ref={videoRef}
      style={{
        backgroundColor: `var(--${staticContent && staticContent.sectionBackgroundColor.iv[0].data.name.iv})`,
        color: `var(--${staticContent && staticContent.contentTextColor.iv[0].data.name.iv})`
      }}
    >
      <div className={styles["inner-wrapper"]}>
        <div className={styles["main-title-wrapper"]}>
          <h2 style={{ color: `var(--${staticContent && staticContent.titleTextColor.iv[0].data.name.iv})` }}>{staticContent && staticContent.title[props.state.languageCode]}</h2>
          <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} className={`button ${styles["btn-expand"]}`} onClick={() => manualOnClickExpandButton()} style={{ color: `var(--${staticContent && staticContent.btnExpandIconColor.iv[0].data.name.iv}` }} />
        </div>
        <div className={`${styles["video-section-wrapper"]} ${expand && styles["expand"]}`}>
          <VideoSection staticContent={staticContent}/>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        uiInteraction: state.uiInteraction
      }
    };
  })(Index)
);
