import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import _ from 'lodash';

import * as AccountAction from "../../../redux/actions/accountAction";

import styles from "./index.module.css";

function Index(props) {
  const namecardRef = useRef(null);
  const [staticContent, setStaticContent] = useState(null);
  const [shareMessage, setShareMessage] = useState(null);
  const [jwtPayload, setJWTPayload] = useState(null);
  const [cmsJson, setCmsJson] = useState(null);
  const [shareUrl, setShareUrl] = useState(`${process.env.REACT_APP_ReferralShortURL}${props.referralCode}`);

  // const getCMSStaticContent = async () => {
  //   if (props.state.jsonMapping.queryNameCardStaticContentContents) {
  //     let cmsStaticContentJson = await axios({
  //       method: "get",
  //       url: props.state.jsonMapping.queryNameCardStaticContentContents.StaticJsonFile.CDNUrl
  //     });
  //     setStaticContent(cmsStaticContentJson.data.map(d => d.data)[0]);
  //   }
  // };

  // const getShareMessageJson = async () => {
  //   if (props.state.jsonMapping.queryShareMessageContents) {
  //     let cmsStaticContentJson = await axios({
  //       method: "get",
  //       url: props.state.jsonMapping.queryShareMessageContents.StaticJsonFile.CDNUrl
  //     });
  //     setShareMessage(cmsStaticContentJson.data.map(d => d.data)[0]);
  //   }
  // };

  const getCMSJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryNameCardStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  const getShareMessageJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryShareMessageContents`,
      method: "get"
    });
    setCmsJson(cmsJson);

    let cmsDataIndex = -1;
    if (!_.isEmpty(props.state.service.serviceType)) {
      cmsDataIndex = _.findIndex(cmsJson.data, { 'data': { 'serviceType': { 'iv': [{ "data": { "abbreviation": { "iv": props.state.service.serviceType } } }] } } });
    }
    if (props.state.account.showDefault === true || cmsDataIndex === -1) {
      setDefaultShareMessage(cmsJson);
      console.log("default")
    } else {
      setShareMessage(cmsJson.data.map(d => d.data)[cmsDataIndex]);
      console.log(props.state.service.serviceType + " " + cmsDataIndex)
    }
  };

  useEffect(() => {
    if (cmsJson && (shareMessage === undefined || shareMessage === null)) {
      //setDefaultShareMessage(cmsJson);
    }
  }, [cmsJson, shareMessage])

  useEffect(() => {
    console.log(shareMessage);
    if (shareMessage && (shareMessage !== undefined || shareMessage !== null))
      if (shareMessage.nameCardRedirectLink && !_.isEmpty(shareMessage.nameCardRedirectLink.iv)) {
        var lang = props.state.languageCode;
        switch (lang) {
          case "zh_HK":
            lang = "tc";
            break;
        }
        var url = _.replace(shareMessage.nameCardRedirectLink.iv, '{0}', lang);
        url = _.replace(url, '{1}', props.referralCode);
        setShareUrl(url);
      } else {
        setShareUrl(`${process.env.REACT_APP_ReferralShortURL}${props.referralCode}`);
      }
  }, [shareMessage])

  const setDefaultShareMessage = (cmsJson) => {
    let cmsDataIndex = _.findIndex(cmsJson.data, { 'data': { 'isDefault': { "iv": true } } });
    setShareMessage(cmsJson.data.map(d => d.data)[cmsDataIndex]);
  }

  useEffect(() => {
    getCMSJson();
    getShareMessageJson();
  }, []);

  const exportJpg = () => {
    let scale = window.innerWidth <= 640 ? 2 : 1;
    html2canvas(namecardRef.current, { allowTaint: false, useCORS: true, logging: false, scale }).then(function (canvas) {
      saveAs(canvas.toDataURL(), "NameCard.jpg");
    });
  };

  const saveAs = (uri, filename) => {
    let link = document.createElement("a");
    // for myhkbn
    if (window &&
      window.navigator &&
      window.navigator.userAgent &&
      (window.navigator.userAgent.indexOf("MyHKBNApp;Android") > -1 ||
        window.navigator.userAgent.indexOf("MyHKBNApp;iOS") > -1)) {
      // support myHKBN In app browser to share
      var dsBridge = require("dsbridge");
      var params = {
        "type": "operation",
        "function": "Image.saveFromUrl",
        "extraData": uri
      }
      dsBridge.call("nativeAsyncBridgeMessage", JSON.stringify(params), (callbackParam) => myHkbnCallback(callbackParam));
    } else {
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    }
  };

  const myHkbnCallback = (callbackParam) => { }

  const getImageUrl = url => {
    //return `${process.env.REACT_APP_Api_Url}/proxy-cdn-file?path=${decodeURIComponent(new URL(url).pathname)}`;
    return `${url}`;
  };

  const closeOverlay = () => {
    props.dispatch(
      AccountAction.updateValue({
        name: "showNamecard",
        value: false
      })
    );
  };

  // useEffect(() => {
  //   getCMSStaticContent();
  //   getShareMessageJson();
  // }, [props.state.jsonMapping]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["inner-wrapper"]}>
        <FontAwesomeIcon icon={faTimes} className={`button ${styles["btn-close"]}`} onClick={() => closeOverlay()} />
        <div className={styles["name-card-wrapper"]} ref={namecardRef}>
          <img src={shareMessage && getImageUrl(shareMessage.namecardBackground[props.state.languageCode][0].url)} />
          <div className={styles["qr-code"]}>
            {/* <QRCode value={`${process.env.REACT_APP_ReferralShortURL}${props.referralCode}`} level={`H`} renderAs={"svg"} /> */}
            <QRCode value={`${shareUrl}`} level={`H`} renderAs={"svg"} />
          </div>
          {/* <div className={styles["referral-url"]}>{`https://r.hkbn.net/${props.referralCode}`}</div> */}
          <div className={styles["referral-url"]}>{`${shareUrl}`}</div>
          <div className={styles["referral-code"]} style={{ color: `var(--${staticContent && staticContent.nameCardReferralCodeTextColor.iv[0].data.name.iv})` }}>
            {props.referralCode}
          </div>
        </div>
        <div className={`button ${styles["btn-download"]}`} onClick={() => exportJpg()} style={{ color: `var(--${staticContent && staticContent.btnDownloadTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${staticContent && staticContent.btnDownloadBackgroundColor.iv[0].data.name.iv})` }}>
          {staticContent && staticContent.btnDownload[props.state.languageCode]}
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account,
        service: state.service
      }
    };
  })(Index)
);
