import React, { Component, Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import { Header, Footer } from "all-in-one-web-header-footer";
// import Header from "./HeaderFooter/navbar/navIndex"
// import Footer from "./HeaderFooter/footer/footerIndex"
import i18n from "../i18next";
import * as cookie from "../cookie";

import Landing from "./Landing/index";
import ReferralHistory from "./ReferralHistory/index";
import SSO from "./SSO/index";
import SocialLogin from "./SocialLogin/index";
import TNC from "./Landing/tnc";

import PrivateRoute from "./PrivateRoute";

import * as helper from "../helper";
  
import * as i18nAction from "../redux/actions/i18nAction";
import * as AccountAction from "../redux/actions/accountAction";
import * as cmsAction from "../redux/actions/cmsAction";
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons";

function RouteMain(props) {
  const [isLogin, setIsLogin] = useState(false);
  const [routerLang, setRouterLang] = useState(props.match.params.lang);
  // this.toggleLanguage = this.toggleLanguage.bind(this);

  useEffect(() => {
    toggleLanguage(props.match.params.lang);
    loadCookieJWT();
  }, []);

  const loadCookieJWT = () => {
    let cookieJWT = cookie.getCookie("jwt_token");
    let jwt = null;

    if (cookieJWT) {
      jwt = cookieJWT;
    }

    if (jwt) {
      props.dispatch(
        AccountAction.updateValue({
          name: "jwt",
          value: jwt
        })
      );
    }
  };

  function toggleLanguage(newLanguage) {
    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
    }
    switch (newLanguage) {
      case "en":
        props.dispatch(i18nAction.updateValue({ name: "languageCode", value: newLanguage }));
        break;
      case "tc":
        props.dispatch(i18nAction.updateValue({ name: "languageCode", value: "zh_HK" }));
        break;
    }
  }

  function convertHeaderFooterLanguage(lang) {
    switch (lang) {
      case "tc":
        return "chi";
      case "en":
        return "eng";
    }
  }

  function changeLanguage() {
    let redirectLang;
    switch (routerLang) {
      case "tc":
        redirectLang = "en";
        break;
      case "en":
        redirectLang = "tc";
        break;
    }
    props.history.push(`/${redirectLang}`);
    toggleLanguage(redirectLang);
    setRouterLang(redirectLang);
  }

  useEffect(() => {
    const getJsonMapping = async () => {
      /*
      let jsonMapping = window.jsonMapping;
      if (window.jsonMapping.match(/^https:\/\//)) {
        let jsonMappingResponse = await axios({
          url: window.jsonMapping,
          method: "get"
        });
        jsonMapping = jsonMappingResponse.data;
      } else {
        jsonMapping = JSON.parse(window.jsonMapping);
      }
      props.dispatch(
        cmsAction.updateValue({
          name: "jsonMapping",
          value: jsonMapping
        })
      );
      */
    };
    getJsonMapping();
  }, []);

  return (
    <Fragment>
      <Header
        isLogin={isLogin}
        handleLoginPress={() => {
          //  when login button is press function
          this.setState({ isLogin: !isLogin });
        }}
        currentLang={convertHeaderFooterLanguage(routerLang)}
        handleChangeLang={() => {
          changeLanguage();
        }}
        onURLClick={(fullURL, path, caller, siteOrigin) => {
          window.location.href = fullURL;
        }}
        aioURL={"http://www.hkbn.net/personal/"}
        myaccountURL={"http://www.hkbn.net/"}
        darkMode={false}
        disableLogin={true}
      />
      <Switch>
        <Route exact path={`${props.match.path}`} component={Landing} />
        <Route exact path={`${props.match.path}/tnc`} component={TNC} />
        <Route exact path={`${props.match.path}/sso`} component={SSO} />
        <Route exact path={`${props.match.path}/social-login`} component={SocialLogin} />
        <PrivateRoute exact path={`${props.match.path}/referral-history`} component={ReferralHistory} />
        <Redirect exact from="*" to="/" /> 
      </Switch>
      <Footer
        currentLang={convertHeaderFooterLanguage(routerLang)}
        handleChangeLang={() => {
          // handle change lang function
        }}
        onURLClick={(fullURL, path, caller, siteOrigin) => {
          window.location.href = fullURL;
        }}
        aioURL={"http://www.hkbn.net/personal/"}
        myaccountURL={"http://www.hkbn.net/"}
      />
    </Fragment>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        i18n: state.i18n
      }
    };
  })(RouteMain)
);
