import dayjs, { Dayjs } from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import dayjsPluginIsBetween from 'dayjs/plugin/isBetween'

class DayjsHandler {
  static _instance;
  constructor() {
    dayjs.extend(dayjsPluginUTC);
    dayjs.extend(dayjsPluginIsBetween)
    this.dayjs = dayjs;
  }

  static getInstance() {
    if (!DayjsHandler._instance) {
      DayjsHandler._instance = new DayjsHandler();
    }
    return DayjsHandler._instance;
  }
}

export default DayjsHandler;
