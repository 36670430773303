import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import dayjs from "dayjs";

import ShareSection from "./share-section";
import ReferralRecord from "./referral-record";

import styles from "./index.module.css";

function Dashboard(props) {
  const [staticContent, setStaticContent] = useState(null);

  const getCMSStaticContentJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryDashboardStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  useEffect(() => {
    getCMSStaticContentJson()
  }, []);

  return (
    <div className={styles["main-wrapper"]} style={{color: `var(--${staticContent && staticContent.contentTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${staticContent && staticContent.sectionBackgroundColor.iv[0].data.name.iv})`}}>
      <div className={styles["inner-wrapper"]}>
        <ShareSection staticContent={staticContent} />
        <ReferralRecord staticContent={staticContent} />
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account
      }
    };
  })(Dashboard)
);
