import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import YouTube from "react-youtube";
import parseHtml from "react-html-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import styles from "./video-section.module.css";

function VideoSection(props) {
  const [cmsContent, setCMSContent] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  // const getCMSVideoSectionJson = async () => {
  //   if (props.state.jsonMapping.queryVideoSectionContents) {
  //     let cmsStaticContentJson = await axios({
  //       method: "get",
  //       url: props.state.jsonMapping.queryVideoSectionContents.StaticJsonFile.CDNUrl
  //     });
  //     setCMSContent(cmsStaticContentJson.data.map(d => d.data));
  //   }
  // };

  // useEffect(() => {
  //   getCMSVideoSectionJson();
  // }, [props.state.jsonMapping]);

  const getCMSJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryVideoSectionContents`,
      method: "get"
    });
    setCMSContent(cmsJson.data.map(d => d.data));
  };

  useEffect(() => {
    getCMSJson();
  }, []);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["inner-wrapper"]}>
        <div
          className={styles["tab-buttons-wrapper"]}
          style={{
            backgroundColor: `var(--${props.staticContent && props.staticContent.tabButtonBackgroundColor.iv[0].data.name.iv})`,
            color: `var(--${props.staticContent && props.staticContent.tabButtonTextColor.iv[0].data.name.iv})`
          }}
        >
          {cmsContent &&
            cmsContent.map((c, i) => (
              <div
                key={i}
                className={`button ${styles["tab-button"]} ${activeIndex == i && styles["active"]}`}
                onClick={() => setActiveIndex(i)}
                style={{
                  backgroundColor: `${activeIndex == i ? `var(--${props.staticContent && props.staticContent.tabButtonBackgroundColorOnSelect.iv[0].data.name.iv})` : `unset`}`,
                  color: `${activeIndex == i ? `var(--${props.staticContent && props.staticContent.tabButtonTextColorOnSelect.iv[0].data.name.iv})` : `unset`}`
                }}
              >
                <div className={styles["inner-wrapper"]}>
                  <div className={styles["text"]}>{c.tabButtonTitle[props.state.languageCode]}</div>
                </div>
                {activeIndex == i && (
                  <div
                    className={styles["active-bar"]}
                    style={{
                      backgroundColor: `var(--${props.staticContent && props.staticContent.tabButtonUnderlineColorOnSelect.iv[0].data.name.iv})`,
                    }}
                  ></div>
                )}
              </div>
            ))}
        </div>
        <div className={styles["video-section-wrapper"]} style={{ backgroundColor: `var(--${props.staticContent && props.staticContent.tabContentBackgroundColor.iv[0].data.name.iv})` }}>
          <div className={styles["left-wrapper"]}>
            <h3 className={styles["subtitle"]}>{parseHtml(cmsContent && cmsContent[activeIndex].subtitle[props.state.languageCode])}</h3>
            {cmsContent &&
              cmsContent[activeIndex].point[props.state.languageCode].map((point, index) => (
                <div key={index} className={styles["point-wrapper"]}>
                  <div
                    className={styles["point-no"]}
                    style={{
                      backgroundColor: `var(--${props.staticContent && props.staticContent.pointBulletBackgroundColor.iv[0].data.name.iv})`,
                      color: `var(--${props.staticContent && props.staticContent.pointBulletTextColor.iv[0].data.name.iv})`
                    }}
                  >
                    {index + 1}
                  </div>
                  <div className={styles["icon-wrapper"]}>
                    <img src={point.icon[0].url} />
                  </div>
                  <div className={styles["desc"]}>{parseHtml(point.text)}</div>
                </div>
              ))}
          </div>
          <div className={styles["right-wrapper"]}>
            {cmsContent && (
              <YouTube
                videoId={cmsContent[activeIndex].youtubeID[props.state.languageCode]}
                containerClassName={styles["youtube-player"]}
                opts={{
                  playerVars: {
                    // autoplay: 1,
                    fs: 0,
                    controls: 1,
                    loop: 1,
                    rel: 0
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(VideoSection)
);
