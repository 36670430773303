import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import parseHtml from "react-html-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./get-plan.module.css";

function Account(props) {
  const [checkPromoCodeLoading, setCheckPromoCodeLoading] = useState(false);
  const [checkPromoCodeCode, setCheckPromoCodeCode] = useState(null);
  const [checkPromocodeErrorMessage, setCheckPromocodeErrorMessage] = useState(null);

  const checkPromoCode = async () => {
    if (checkPromoCodeCode) {
      let valid = await checkPromoCodeRequest(checkPromoCodeCode);
      if (valid) {
        let redirectLinkFromSquidex = props && props.staticContent && props.staticContent.redirectRefereeUrl && props.staticContent.redirectRefereeUrl[props.state.languageCode];
        if(redirectLinkFromSquidex != null){
          let openLink = (redirectLinkFromSquidex.replace("{code}", checkPromoCodeCode));
          //window.open(`https://www.hkbn.net/personal/Redirect/Landing/Mobile/tc/${checkPromoCodeCode}`);
          window.open(openLink);
        }
      } else {
        setCheckPromocodeErrorMessage(props.staticContent.checkCodeInvalidCode[props.state.languageCode]);
      }
    }
  };

  const checkPromoCodeRequest = async code => {
    if (!checkPromoCodeLoading) {
      setCheckPromoCodeLoading(true);
      try {
        let checkPromoCodeResponse = await axios({
          url: `${process.env.REACT_APP_Api_Url}/referral/check-promo-code?code=${code}`,
          method: "get"
        });
        setCheckPromoCodeLoading(false);
        return checkPromoCodeResponse.status == 200;
      } catch (err) {
        console.log(err)
        setCheckPromoCodeLoading(false);
        return false;
      }
    }
  };

  return (
    <div className={styles["get-referral-code-wrapper"]}>
      <div className={styles["get-referral-code-inner-wrapper"]}>
        <h4>{parseHtml(props.staticContent && props.staticContent.getReferralCodeOfferTitle[props.state.languageCode])}</h4>
        <div className={styles["text-box-wrapper"]}>
          <input
            type="text"
            className={`textbox ${styles["txt-phone"]}`}
            placeholder={props.staticContent && props.staticContent.txtReferralCodePlaceHolder[props.state.languageCode]}
            onChange={e => setCheckPromoCodeCode(e.target.value)}
          />
          <div className={`button ${styles["btn-getcode-wrapper"]}`} onClick={() => checkPromoCode()} style={{color: `var(--${props.staticContent && props.staticContent.btnUseReferralCodeIconColor.iv[0].data.name.iv})`, backgroundColor: `var(--${props.staticContent && props.staticContent.btnUseReferralCodeBackgroundColor.iv[0].data.name.iv})`}}>
            <FontAwesomeIcon icon={faChevronRight} className={`button ${styles["btn-getcode"]}`} />
          </div>
        </div>
        <div className={styles["error-message"]}>{checkPromocodeErrorMessage && checkPromocodeErrorMessage}</div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(Account)
);
