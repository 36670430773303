import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import dayjs from "dayjs";

import styles from "./referral-record.module.css";
import HtmlParser from "react-html-parser";
import _ from "lodash";

function ReferralRecord(props) {

  const [historyDescContent, setHistoryDescContent] = useState(null);
  const [historyDescContent4Mobile, setHistoryDescContent4Mobile] = useState(null);
  const [historyDescContent4App, setHistoryDescContent4App] = useState(null);
  const [isApp, setIsApp] = useState(_.includes(_.toLower(navigator.userAgent), "myhkbnapp"));

  useEffect(() => {
    if (props.staticContent) {
      setHistoryDescContent(props.staticContent.referralHistoryDescription[props.state.languageCode]);
      setHistoryDescContent4Mobile(props.staticContent.referralHistoryDescription4Mobile[props.state.languageCode]);
      setHistoryDescContent4App(props.staticContent.referralHistoryDescription4App[props.state.languageCode]);
    }
    console.log("isApp: " + isApp);
  }, [props.staticContent, props.state.languageCode]);

  const appButtonOnClick = (e) => {
    e.preventDefault();
    var dsBridge = require("dsbridge");
    let data = null;
    if (_.includes(window.location.hash, "#reward-offer-fromhist")) {
      // delete comment when prod
      console.log("fromhist")
      data = { "type": "navigation", "function": "pop" };
    } else {
      // delete comment when prod
      console.log("not fromhist")
      data = { "type": "navigation", "function": "push", "screenName": "ReferralHistory", "params": {} };
    }
    dsBridge.call("nativeAsyncBridgeMessage", JSON.stringify(data), function (v) { });
  }

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["inner-wrapper"]}>
        <div className={styles["referral-record-wrapper"]}>
          <h3>{props.staticContent && props.staticContent.referralHistoryTitle[props.state.languageCode]}</h3>
          <div className={styles["desc"]}>{HtmlParser(historyDescContent)}</div>
          {!isApp && <div className={styles["descMobile"]}>{HtmlParser(historyDescContent4Mobile)}</div>}
          {isApp && <div className={styles["descApp"]}>{HtmlParser(historyDescContent4App)}</div>}
        </div>
        <style>
          {`
                    .${styles["btn-history-wrapper"]}  {
                      color: var(--${props.staticContent && props.staticContent.buttonRedirectLinkTextColor.iv[0].data.name.iv});
                      background-color: var(--${props.staticContent && props.staticContent.buttonRedirectLinkBackgroundColor.iv[0].data.name.iv});
                    }

                  `}
        </style>
        {/* <Link to={`${props.match.url}/referral-history`} className={`button ${styles["btn-history-wrapper"]}`}>
          <span>{props.staticContent && props.staticContent.buttonRedirectReferralHistoryPage[props.state.languageCode]}</span>
        </Link> */}
        {!isApp && <a href={props.staticContent && props.staticContent.buttonRedirectLinkUrl[props.state.languageCode]} className={`button ${styles["btn-history-wrapper"]}`}>
          <img className={styles["btn-history-icon"]} src={props.staticContent && props.staticContent.buttonRedirectLinkIcon[props.state.languageCode][0].url} />
          <span>&nbsp;&nbsp;</span>
          <span>{props.staticContent && props.staticContent.buttonRedirectReferralHistoryPage[props.state.languageCode]}</span>
        </a>}

        {/* Show on App only */}
        {isApp && <button className={`button ${styles["btn-history-wrapper"]}`} onClick={appButtonOnClick}>
          <span>{props.staticContent && props.staticContent.buttonRedirectReferralHistoryPage4App[props.state.languageCode]}</span>
        </button>}
        <img className={styles["img-myhkbn-link"]} src={props.staticContent && props.staticContent.buttonRedirectLinkImage.iv[0].url} />
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account
      }
    };
  })(ReferralRecord)
);
