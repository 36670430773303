export const setCookie = (cname, cvalue, exp) => {
  var d = new Date(0)
  d.setUTCSeconds(exp)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/; SameSite=Strict;' + `${process.env.NODE_ENV == "development" ? "" : "Secure"};domain=` + window.location.hostname;
}


export const getCookie = cname => {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}