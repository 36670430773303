import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import MainBanner from "./main-banner";
import Account from "./Account/index";
import Dashboard from "./Dashboard/index";
import VideoSection from "./VideoSection/index";
import RewardOffer from "./RewardOffer/index";
import TacitalBanner from "./tacital-banner";
import Plan from "./Plan/index";
import QNA from "./qna";

import styles from "./index.module.css";

function Landing(props) {
  const [landingOrdering, setLandingOrdering] = useState([]);

  const getSection = (name, key) => {
    switch (name) {
      case "main-banner":
        return <MainBanner key={key} />;
      case "account":
        return props.state.account.jwt != null ? <Dashboard key={key}/> : <Account key={key} />;
      case "video":
        return <VideoSection key={key} />;
      case "reward-offer":
        return <RewardOffer key={key}/>;
      case "tactical":
        return <TacitalBanner key={key}/>;
      case "plan":
        return <Plan key={key}/>;
      case "qna":
        return <QNA key={key}/>;
    }
  };

  const getCMSJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryOrderingContents`,
      method: "get"
    });
    setLandingOrdering(cmsJson.data.map(d => d.data)[0].webLayout.iv.map(d => d.section));
  };

  useEffect(() => {
    getCMSJson();
  }, []);

  return <div className={styles["main-wrapper"]}>{landingOrdering.map((l, key) => getSection(l, key))}</div>;
}

export default withRouter(
  connect(state => {
    return {
      state: {
        account: state.account,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(Landing)
);
