import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as cookie from "../../cookie";
import * as helper from "../../helper";

import * as AccountAction from "../../redux/actions/accountAction";

function Index(props) {
  const loadJWT = () => {
    let cookieJWT = cookie.getCookie("jwt_token");
    let jwt = null;
    if (cookieJWT) {
      jwt = cookieJWT;
    } else if (window.jwt) {
      jwt = window.jwt;
    }

    if (jwt) {
      props.dispatch(
        AccountAction.updateValue({
          name: "jwt",
          value: jwt
        })
      );
      cookie.setCookie("jwt_token", jwt, helper.parseJwt(jwt).exp);
    }

    props.history.push(`/${props.match.params.lang}`);
  };

  useEffect(() => {
    loadJWT();
  }, []);

  return <div>Loading...</div>;
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode
      }
    };
  })(Index)
);
