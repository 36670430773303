import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";

class LanguageNotFound extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.history.push("/tc");
  }
  render() {
    return <Fragment />;
  }
}

export default withRouter(LanguageNotFound);
