import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import htmlParser from "react-html-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import styles from "./qna.module.css";

function QNA(props) {
  const [qnaList, setQnaList] = useState([]);
  const [staticContent, setStaticContent] = useState(null);
  const [expandQNA, setExpandQNA] = useState(false);

  const triggerExpandQna = index => {
    const cloneQnaList = JSON.parse(JSON.stringify(qnaList));
    cloneQnaList[index].Expand = !cloneQnaList[index].Expand;
    setQnaList(cloneQnaList);
  };

  const getCMSJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryQnaContents`,
      method: "get"
    });
    setQnaList(cmsJson.data.map(d => d.data));
  };

  const getCMSStaticContentJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryQnaStaticContentContents`,
      method: "get"
    });
    setStaticContent(cmsJson.data.map(d => d.data)[0]);
  };

  useEffect(() => {
    getCMSJson();
    getCMSStaticContentJson();
  }, []);

  return (
    <div
      className={styles["main-wrapper"]}
      style={{
        backgroundColor: `var(--${staticContent && staticContent.sectionBackgroundColor.iv[0].data.name.iv})`,
        color: `var(--${staticContent && staticContent.contentTextColor.iv[0].data.name.iv})`
      }}
    >
      <div className={styles["inner-wrapper"]}>
        <div className={styles["title-wrapper"]}>
          <h2
            style={{
              color: `var(--${staticContent && staticContent.titleTextColor.iv[0].data.name.iv})`
            }}
          >
            {staticContent && staticContent.title[props.state.languageCode]}
          </h2>
          <FontAwesomeIcon
            icon={expandQNA === false ? faChevronDown : faChevronUp}
            onClick={() => setExpandQNA(!expandQNA)}
            className={`button ${styles["btn-expand-qna"]}`}
            style={{ color: `var(--${staticContent && (expandQNA === false ? staticContent.titleExpandIconColor.iv[0].data.name.iv : staticContent.titleCollapseIconColor.iv[0].data.name.iv)})` }}
          />
        </div>
        {expandQNA && (
          <div className={`${styles["questions-wrapper"]}`}>
            {qnaList.map((qna, index) => (
              <div
                key={index}
                className={styles["question-wrapper"]}
                style={{
                  borderColor: `var(--${staticContent && staticContent.questionSeparatorColor.iv[0].data.name.iv})`
                }}
              >
                <div
                  className={`button ${styles["question-no"]}`}
                  onClick={() => triggerExpandQna(index)}
                  style={{ color: `var(--${staticContent && staticContent.questionTextColor.iv[0].data.name.iv})` }}
                >{`${index + 1}.`}</div>
                <div className={`button ${styles["question"]}`} onClick={() => triggerExpandQna(index)} style={{ color: `var(--${staticContent && staticContent.questionTextColor.iv[0].data.name.iv})` }}>
                  {qna.question[props.state.languageCode]}
                </div>
                <FontAwesomeIcon
                  icon={qna.Expand === true ? faMinus : faPlus}
                  onClick={() => triggerExpandQna(index)}
                  className={`button ${styles["btn-expand"]}`}
                  style={{
                    color: `var(--${staticContent && (qna.Expand === true ? staticContent.questionCollapseIconColor.iv[0].data.name.iv : staticContent.questionExpandIconColor.iv[0].data.name.iv)})`
                  }}
                />
                <div className={`${styles["answer"]} ${qna.Expand === true ? styles["expand"] : ""}`}>{htmlParser(qna.answer[props.state.languageCode])}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping
      }
    };
  })(QNA)
);
