import React, { Component, Fragment, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import * as helper from '../helper'
import * as cookie from '../cookie'

import * as AccountAction from '../redux/actions/accountAction'

function PrivateRoute({ component: Component, state, ...rest }) {
  const tokenExpire = () => {
    return (
      getJWTCookie() && helper.parseJwt(getJWTCookie()).exp < Date.now() / 1000
    )
  }

  const getJWTCookie = () => {
    return cookie.getCookie('jwt_token')
  }

  return (
    <Route
      {...rest}
      render={(p) => {
        if (getJWTCookie() && !tokenExpire()) {
          return <Component {...p} />
        } else {
          rest.dispatch(
            AccountAction.updateValue({
              name: 'jwt',
              value: null,
            })
          )
          return (
            <Redirect to={`/${rest.match.params.lang}`} />
          )
        }
      }}
    />
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        member: state.member,
      },
    }
  })(PrivateRoute)
)