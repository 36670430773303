import React, { Component, Suspense, Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import dayjs from "dayjs";
import dayjsHandler from "../../dayjsHandler";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./row.module.css";

const dayjsInstance = dayjsHandler.getInstance();

function Row(props) {
  const [expand, setExpand] = useState(false);

  const getStatusIcon = status => {
    switch (status.toLowerCase()) {
      case "pending":
      case "p":
        return (
          <div
            className={`${styles["pending"]} ${styles["status-icon"]}`}
            style={{ backgroundColor: `var(--${props.staticContent && props.staticContent.tableStatusPendingIconBgColor.iv[0].data.name.iv})` }}
          >
            <img src={props.staticContent && props.staticContent.tableStatusPendingIcon.iv[0].url} />
          </div>
        );
      case "fail":
      case "f":
      case "cancel":
      case "c":
      case "unsuccess":
      case "u":
        return (
          <div
            className={`${styles["fail"]} ${styles["status-icon"]}`}
            style={{ backgroundColor: `var(--${props.staticContent && props.staticContent.tableStatusFailIconBgColor.iv[0].data.name.iv})` }}
          >
            <img src={props.staticContent && props.staticContent.tableStatusFailIcon.iv[0].url} />
          </div>
        );
      case "success":
      case "s":
        return (
          <div
            className={`${styles["success"]} ${styles["status-icon"]}`}
            style={{ backgroundColor: `var(--${props.staticContent && props.staticContent.tableStatusSuccessIconBgColor.iv[0].data.name.iv})` }}
          >
            <img src={props.staticContent && props.staticContent.tableStatusSuccessIcon.iv[0].url} />
          </div>
        );
    }
  };

  const getServiceIcon = service => {
    switch (service) {
      case "Boardband":
      case "bn":
        return <img src={props.staticContent && props.staticContent.tableServiceBoardbandIcon.iv[0].url} />;
      case "Mobile":
      case "ms":
        return <img src={props.staticContent && props.staticContent.tableServiceMobileIcon.iv[0].url} />;
    }
  };

  const getExtraReward = rewardIDs => {
    let rewardInfo = props.rewardMapping.filter(rm => {
      return rewardIDs.includes(rm.rewardID.iv);
    });
    return rewardInfo;
  };

  // const getRewardItems = itemArray => {
  //   let item = [];
  //   if(itemArray){
  //     console.log(itemArray);
  //     itemArray.forEach(element => {
  //       if(element!=null){
  //         //TO-DO: get the content of reward item according to the element.id
  //         let newItem = <div className={styles["text"]}>{element.rewardId}</div>;
  //       }
  //     });
  //   }
  //   return item;
  // };

  return (
    <div
      className={`${styles["row"]} ${expand && styles["expand"]}`}
      style={{
        color: `var(--${props.staticContent && props.staticContent.tableContentTextColor.iv[0].data.name.iv})`,
        backgroundColor: `var(--${props.staticContent && props.staticContent.tableBackgroundColor.iv[0].data.name.iv})`
      }}
    >
      <div>{dayjs(props.data.orderDate).format("DD/MM/YYYY")}</div>
      <div className={`${styles["referrer-name"]} ${props.data.isRefereeOrder && styles["nc-order"]}`}>{props.data.maskName}</div>
      <div className={`${styles["text-icon-wrapper"]}`}>
        {/* <div className={styles["item"]}>
          {(false) && (<div className={styles["text"]}>{props.staticContent && props.staticContent.tableRewardAccountReward[props.state.languageCode].replace("###amount###", props.data.isRefereeOrder ? props.data.nC_Rebate : props.data.eC_Rebate)}</div>)}
        </div> */}
        {!expand && (
          <div className={styles["item"]}>
            <div className={`${styles["icon-wrapper"]}`}>
              <img src={props.staticContent && props.staticContent.tableRewardDefaultRewardIcon.iv[0].url} />
            </div>
          </div>
        )}
        {expand && (
          <Fragment>
            {getExtraReward(props.data.rewardIdList).map((r, key) => {
              return (
                <div className={styles["item"]} key={key}>
                  <div className={styles["text"]}>{r.rewards[props.state.languageCode][0].text}</div>
                  <div className={styles["icon-wrapper"]}>{r.rewards[props.state.languageCode][0].icon.length > 0 && <img src={r.rewards[props.state.languageCode][0].icon[0].url} />}</div>
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
      <div className={styles["service-and-status"]}>
        {/*<div className={styles["icon-wrapper"]}>{getServiceIcon(props.data.servicesType)}</div>*/}
        {getStatusIcon(props.data.status)}
      </div>
      <div>
        <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} className={`button ${styles["btn-expand"]}`} onClick={() => setExpand(!expand)} />
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account
      }
    };
  })(Row)
);
