import { combineReducers } from 'redux'
import i18nReducer from './i18nReducer'
import cmsReducer from "./cmsReducer"
import accountReducer from "./accountReducer"
import uiInteractionReducer from './uiInteractionReducer';
import serviceReducer from './serviceReducer';

export default combineReducers({
  i18n: i18nReducer,
  cms: cmsReducer,
  account: accountReducer,
  uiInteraction: uiInteractionReducer,
  service: serviceReducer
})
