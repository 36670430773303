import _ from 'lodash';

export const fireEvent = (type, data = '') => {
  var utag = window.utag;
  var _utagEvents = window._utagEvents;
  if (!_.isEmpty(utag)) {
    utag[type](data);
  } else if (_utagEvents && _.isFunction(_utagEvents.push)) {
    _utagEvents.push([type, data]);
  }
};
