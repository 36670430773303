import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from 'lodash';
import replaceShareMessage from "./replaceShareMessage";

import styles from "./share-button.module.css";

function Index(props) {
  const [shareMessage, setShareMessage] = useState(null);
  const getShareMessageJson = async () => {
    let cmsJson = await axios({
      url: `${process.env.REACT_APP_Api_Url}/getcontent/query/queryShareMessageContents`,
      method: "get"
    });
    setShareMessage(cmsJson.data)
  };

  useEffect(() => {
    getShareMessageJson();
  }, [])

  const getShareMessageData = () => {
    if (shareMessage) {
      let cmsDataIndex = -1;
      if (!_.isEmpty(props.state.service.serviceType)) {
        cmsDataIndex = _.findIndex(shareMessage, { 'data': { 'serviceType': { 'iv': [{ "data": { "abbreviation": { "iv": props.state.service.serviceType } } }] } } });
      }
      if (props.showDefault === true || cmsDataIndex === -1) {
        cmsDataIndex = _.findIndex(shareMessage, { 'data': { 'isDefault': { "iv": true } } });
      }
      if (cmsDataIndex === -1) {
        cmsDataIndex = 0;
      }
      var serviceType = props.state.service.serviceType;
      if(props.isDefault){
        serviceType = '';
      }
      var msg = shareMessage[cmsDataIndex].data.facebookContent[props.state.languageCode];
      msg = replaceShareMessage(msg, "facebook", props.state.languageCode, props.mgmCode, serviceType)
      return msg;
    }
  }
  
  const handleClickShareButton = async () => {
    if (!_.isEmpty(props.mgmCode)) {
      if (window &&
        window.navigator &&
        window.navigator.userAgent &&
        (window.navigator.userAgent.indexOf("MyHKBNApp;Android") > -1 ||
          window.navigator.userAgent.indexOf("MyHKBNApp;iOS") > -1)) {
        // support myHKBN In app browser to share
        var dsBridge = require("dsbridge");
        var params = {
          "type": "operation",
          "function": "share",
          "extraData": getShareMessageData()
        }
        dsBridge.call("nativeAsyncBridgeMessage", JSON.stringify(params), (callbackParam) => myHkbnCallback(callbackParam));
      }
      else {
        let url = await getRedirectUrlRequest(props.mgmCode);
        window.open(url);
      }
    } else {
      props.history.push(`/${props.match.params.lang}#account`);
    }
  };

  const myHkbnCallback = (callbackParam) => { }

  const getRedirectUrlRequest = async code => {
    let serviceType = props.state.service.serviceType;
    let showDefault = props.showDefault;
    if (_.isEmpty(serviceType) || showDefault === true)
      serviceType = "";
    return `${process.env.REACT_APP_Api_Url}/share/customer/facebook?languageCode=${props.match.params.lang}&mgmCode=${code}&serviceType=${serviceType}`;
    // try {
    //   let getRedirectUrlResponse = await axios({
    //     url: `${process.env.REACT_APP_Api_Url}/share/customer/facebook?languageCode=${props.match.params.lang}&mgmCode=${code}&redirect=false`,
    //     method: "get"
    //   });
    //   return getRedirectUrlResponse.data;
    // } catch (err) { }
  };

  return (
    <div className={`button ${styles["button"]}`} onClick={() => handleClickShareButton()} style={{ backgroundColor: `${props && props.backgroundColor}` }}>
      <img src={props.icon} />
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        account: state.account,
        service: state.service
      }
    };
  })(Index)
);
