import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { fireEvent } from './TealiumHelper'

const tealiumRouterListener = function(location, action) {
  fireEvent('view', {
    page_name: location.pathname
  })
}

function Tealium(props) {
  const { history, location } = props
  useEffect(() => {
    // init page view
    fireEvent('view', {
      page_name: location.pathname
    })
    // fire view event after route changed
    const unlisten = history.listen(tealiumRouterListener)
    return unlisten
  }, [])
  return (
    <>
    
    </>
  );
}

export default withRouter(Tealium)