export default (state = {
    manualClickAnchorTimeDict: []
}, action) => {
    switch (action.type) {
        case 'UPDATE_Interaction':
            return {
                ...state,
                manualClickAnchorTimeDict:{
                    ...state.manualClickAnchorTimeDict,[action.payload.name]: action.payload.value
                }
            }
        default:
            return state;
    }
}
